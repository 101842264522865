import Vimeo from '@u-wave/react-vimeo';
import RsList from '../../../../components/rs-elements/rs-list/RsList';
import { RsListItem } from '../../../../components/rs-elements/rs-list/RsListItem';
import { Breakpoints } from '../../../../constants/Generic';
import './HomepageWhatWeDoComponent.scss';

export default function HomepageWhatWeDoComponent() {
  const items: RsListItem[] = [
    new RsListItem('Manage your loan portfolio through its whole lifecycle in one place'),
    new RsListItem('Import your existing loan portfolio to benefit from all digital services'),
    new RsListItem('Discover a new way to request CRE loans and negotiate them fully digitally'),
  ];

  return (
    <div className="homepage-what-we-do-component">
      <h4 className="has-text-centered headline-underlined has-text-weight-bold is-uppercase">What we do</h4>

      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column video-container is-12-tablet is-6-desktop">
            <Vimeo
              showTitle={false}
              style={{ padding: 0 }}
              width={window.innerWidth > Breakpoints.tablet ? '500' : '500'}
              video="https://player.vimeo.com/video/680738361?h=8308299770&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              onError={() => {}}
            />
          </div>
          <div className="column list-container is-12-mobile is-6-desktop">
            <RsList items={items} />
          </div>
        </div>
      </div>
    </div>
  );
}
