import { NextCommandType } from './NextCommandType';

export type PaymentAssignmentAction = 'settle' | 'instruct';
export type AssignmentAction = Exclude<NextCommandType['type'], 'transaction'> | PaymentAssignmentAction;

export const AllAssignmentLabels: Record<AssignmentAction, string> = {
  'amend-structured-loan': 'Amend Structured Loan',
  'base-rate': 'Set base rate',
  'mortgage-bond-coupon': 'Mortgage Bond Coupon',
  'initiate-drawdown': 'Initiate Drawdown',
  'initiate-utilisation': 'Utilisation Request',
  'initiate-termination': 'Initiate Termination',
  'initiate-subperiod-prepayment': 'Initiate Prepayment',
  instruct: 'Instruct payment',
  settle: 'Settle payment',
  sequence: 'Sequence',
  'sl-information-undertaking': 'Collection Event',
  'sl-covenant-report': 'Covenant Report',
  'cast-vote': 'Cast Vote',
  'set-interest-rate': 'Set Interest Rate',
  'set-amortisation': 'Set Amortisation',
};

export type NextCommandTypeWithAssignments = NextCommandType & { assignments: AssignmentAction[] | undefined };
