import { NormalizedPeriodType } from '../covenants/Period';
import { normalizePeriod } from './data-store';

export function lifecycleToPeriod(periodDetails: { 'start-date': string; 'end-date': string }): NormalizedPeriodType {
  const lifecyclePeriod = normalizePeriod({ start: periodDetails['start-date'], end: periodDetails['end-date'] });
  const period: NormalizedPeriodType = {
    start: lifecyclePeriod.start,
    end: lifecyclePeriod.end,
  };
  return period;
}
