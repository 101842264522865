import { InferType, object, string } from 'yup';
import { defineApi } from '../common/api-definition';
import { OutputDto } from '../common/OutputType';
import { SLCovenantReportEventType, SLInformationUndertakingEventType } from '../loan/EventType';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';
import { CloseReportingEventArgs } from './CloseReportingEventArgs';
import {
  InformationUndertakingSLEventParams,
  SkipInformationUndertakingSLEvent,
  SubmitInformationUndertakingSLEvent,
  UpdateInformationUndertakingSLEvent,
} from './InformationUndertakingType';
import { CCPreviewDTO } from './ReportingEventDTO';

const closeReportingEvent = defineApi<{ body: CloseReportingEventArgs; response: { success: true } }>({
  url: 'structured-loans/close-reporting-event',
  method: 'post',
});

type SLCovenantEvent = SLInformationUndertakingEventType | SLCovenantReportEventType;

const covenantEvents = defineApi<{
  body: { loanIds: string[] };
  response: { [loanId: string]: SLCovenantEvent[] };
}>({
  url: 'structured-loans/covenant-events',
  method: 'post',
});

const generateOutboundNotification = defineApi<{
  body: { loanId: string; reportingEventId: string; date: string };
  response: OutputDto<true>;
}>({
  url: 'structured-loans/generate-outbound-notification-for-covenant-report',
  method: 'post',
});

export const getCertificatePreviewValidation = object({
  loanId: string().required(),
  reportId: string().required(),
  checkDate: string().required(),
});

const getCertificatePreview = defineApi<{
  params: InferType<typeof getCertificatePreviewValidation>;
  response: CCPreviewDTO;
}>({
  url: 'structured-loans/covenant/:loanId/report/:reportId/checkDate/:checkDate/preview',
  method: 'get',
});

export const getCertificateSigningUrlRedirectValidation = object({
  loanId: string().required(),
  reportId: string().required(),
  date: string().required(),
  returnUrl: string().required(),
}).required();

const getCertificateSigningUrlRedirect = defineApi<{
  body: InferType<typeof getCertificateSigningUrlRedirectValidation>;
  response: { type: 'url'; url: string };
}>({
  url: 'structured-loans/covenant-certificate-signing-redirect',
  method: 'post',
});

export type ModifyInformationUndertakingRequest = {
  params: InformationUndertakingSLEventParams;
  event: UpdateInformationUndertakingSLEvent | SubmitInformationUndertakingSLEvent | SkipInformationUndertakingSLEvent;
};

const modifyInformationUndertaking = defineApi<{
  body: ModifyInformationUndertakingRequest;
  response: { message: string };
}>({
  url: 'structured-loans/modify-information-undertaking',
  method: 'post',
});

export const fetchPropertyValuationValidation = ObjectStripUnknown({
  loanId: TextStripEmpty.required(),
  propertyId: TextStripEmpty.required(),
  date: TextStripEmpty.required(),
});

export const covenantApi = {
  closeReportingEvent,
  covenantEvents,
  generateOutboundNotification,
  getCertificatePreview,
  getCertificateSigningUrlRedirect,
  modifyInformationUndertaking,
  fetchPropertyValuation: defineApi<{
    body: InferType<typeof fetchPropertyValuationValidation>;
    response: OutputDto<string>;
  }>({
    method: 'post',
    url: 'structured-loans/fetch-property-valuation',
  }),
};
