import { InferType, mixed, string } from 'yup';
import { defineApi } from '../common/api-definition';
import { OutputDto } from '../common/OutputType';
import { AdapterKey, adapterKeys } from '../utils/adapters';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';
import { UpdateCompanyConfigurations } from './update-company-configurations-dto.type';

const companyUsersSchema = ObjectStripUnknown({
  companyId: TextStripEmpty,
});

/**
 * Get the names and ids of users of a company.
 *
 * There is a similar endpoint in serverless, but that is only for the company admin
 * and returns a lot more data.
 */
const companyUsers = defineApi<{
  body: InferType<typeof companyUsersSchema>;
  response: { id: string; name: string }[];
}>({ url: 'company/users', method: 'post' });

const testCredentialsSchema = ObjectStripUnknown({
  adapter: string().required().oneOf(adapterKeys),
  configuration: mixed().required(),
});

const testCredentials = defineApi<{
  response: OutputDto<boolean>;
  body: { adapter: AdapterKey; configuration: unknown };
}>({
  method: 'post',
  url: 'company/test-credentials',
});

const runBackup = defineApi<{ response: OutputDto<boolean> }>({
  url: 'company/run-backup',
  method: 'post',
});

const runFetchBaseRates = defineApi<{ response: { quotationDates: string[] } }>({
  url: 'company/run-fetch-base-rates',
  method: 'post',
});

const updateConfigurationSchema = ObjectStripUnknown({
  configurations: ObjectStripUnknown({
    loanReconciliation: mixed().optional(),
    backup: mixed().optional(),
    propertyValuation: mixed().optional(),
    baseRates: mixed().optional(),
    adapterKeys: ObjectStripUnknown(Object.fromEntries(adapterKeys.map(k => [k, string().optional()]))).optional(),
  }),
});

const updateConfiguration = defineApi<{
  body: UpdateCompanyConfigurations;
  response: OutputDto<boolean>;
}>({
  url: 'company/configurations',
  method: 'post',
});

const validateApiConfigurationSchema = ObjectStripUnknown({
  adapter: string().required().oneOf(adapterKeys),
  configuration: mixed<unknown>().default(undefined).required(),
});

const validateApiConfiguration = defineApi<{
  response: string[];
  body: InferType<typeof validateApiConfigurationSchema>;
}>({
  method: 'post',
  url: 'company/validate-api-configuration',
});

export type ConfigurationExample =
  | {
      tag: 'available';
      example: Record<string, unknown>;
    }
  | { tag: 'unavailable' };

const getConfigurationExamples = defineApi<{
  response: Record<AdapterKey, ConfigurationExample>;
}>({ url: 'company/get-configuration-examples', method: 'get' });

export const companyApi = {
  companyUsersSchema,
  companyUsers,
  validateApiConfigurationSchema,
  validateApiConfiguration,
  updateConfigurationSchema,
  updateConfiguration,
  testCredentialsSchema,
  testCredentials,
  runBackup,
  runFetchBaseRates,
  getConfigurationExamples,
};
