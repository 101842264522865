import { defineApi } from '../common/api-definition';
import { InformationUndertakingSpecDraftType } from '../covenants/InformationUndertakingSpecType';
import { LoanMetricDraftType } from '../covenants/LoanMetricType';
import { SLReportingEventConfigDraft } from '../covenants/ReportingEventConfigType';
import { SLFacilityViewType } from './SLFacilityViewType';

export type AgreementReportingEventView = {
  version: number;
  re: SLReportingEventConfigDraft;
  metrics: LoanMetricDraftType[];
  facilities: SLFacilityViewType[];
  loanEntities: string[];
  /** Collection events only exist when the loan is running. */
  collectionEvents: InformationUndertakingSpecDraftType[] | undefined;
};

export const agreementReportingEventViewApi = defineApi<{
  body: { loanId: string; reId: string };
  response: AgreementReportingEventView;
}>({
  url: 'structured-loans/agreement-reporting-event-view',
  method: 'post',
});
