import { defineApi } from '../common/api-definition';

export type ActivePeriodsListType = {
  facilityId: string;
  sequenceId: string;
  periodIndex?: number;
  subperiodPrepaymentAvailable: boolean;
  minSubperiodPrepaymentDate?: string;
  maxSubperiodPrepaymentDate?: string;
  availablePeriods?: { 'period-index': number; 'value-date': string }[];
};

export type PrepaymentDialogView = ActivePeriodsListType[];

export type PrepaymentDialogViewRequest = {
  loanId: string;
};

export const prepaymentDialogViewApi = defineApi<{
  body: PrepaymentDialogViewRequest;
  response: PrepaymentDialogView;
}>({
  url: 'structured-loans/prepayment-dialog-view',
  method: 'post',
});
