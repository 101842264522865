export const AllPrepaymentReasons = {
  covenantBreach: 'Covenant Breach',
  voluntaryPrepayment: 'Voluntary Prepayment',
  termination: 'Termination',
  other: 'Other',
} as const;

export type PrepaymentReasonType =
  | {
      type: 'covenantBreach' | 'voluntaryPrepayment' | 'termination';
    }
  | {
      type: 'other';
      text: string;
    };

export type PrepaymentFeeType =
  | {
      type: 'default';
    }
  | {
      type: 'custom';
      value: number;
    };
