import { InferType, string } from 'yup';
import { CompanyTypeCombinations } from '../access-rights/ModuleAccessActionsI';
import { defineApi } from '../common/api-definition';
import { type OutputDto } from '../common/OutputType';
import { ObjectStripUnknown, RequiredArray, TextStripEmpty } from '../validation/atoms';
import { AdapterKey } from './adapters';

const getAllCompanyOfferings = defineApi<{
  response: { id: string; name: string; companyTypes: CompanyTypeCombinations; module: string }[];
}>({
  url: 'platform-admin/get-all-company-offerings',
  method: 'get',
});

export const getCompanyOfferingApiValidationSchema = ObjectStripUnknown({ companyId: string() });

const getCompanyOfferingsApi = defineApi<{
  params: InferType<typeof getCompanyOfferingApiValidationSchema>;
  response: string[];
}>({
  url: 'platform-admin/get-company-offerings/:companyId',
  method: 'get',
});

export const setCompanyOfferingApiValidationSchema = ObjectStripUnknown({
  companyId: string().required(),
  roleIds: RequiredArray(string()),
});

const setCompanyOfferingsApi = defineApi<{
  body: { companyId: string; roleIds: readonly string[] };
  response: OutputDto<true>;
}>({
  url: 'platform-admin/set-company-offerings',
  method: 'post',
});

const getAdapterOptions = defineApi<{
  response: Record<AdapterKey, string[]>;
}>({ url: 'platform-admin/get-adapter-options', method: 'get' });

export const setAdapterKeysSchema = ObjectStripUnknown({
  companyId: string(),
  adapters: ObjectStripUnknown<Record<AdapterKey, string | undefined>>({
    'loan-reconciliation': TextStripEmpty.optional().min(1),
    'property-valuation': TextStripEmpty.optional().min(1),
    backup: TextStripEmpty.optional().min(1),
    'base-rates': TextStripEmpty.optional().min(1),
  }),
});

const setAdapterKeys = defineApi<{ response: OutputDto<boolean>; body: InferType<typeof setAdapterKeysSchema> }>({
  url: 'platform-admin/set-adapter-keys',
  method: 'post',
});

export const platformAdminApi = {
  getAllCompanyOfferings,
  getCompanyOfferingsApi,
  setCompanyOfferingsApi,
  getAdapterOptions,
  setAdapterKeys,
} as const;
