import { Field, FieldAttributes, useField } from 'formik';
import React, { Fragment, useContext } from 'react';
import RsInfo from '../../rs-elements/rs-info/RsInfo';
import RsLoading from '../../rs-loading/RsLoading';
import RsLabel from '../rs-label/RsLabel';
import RsFieldError from '../RsFieldError';
import { RsFormContext } from '../RsForm';
import './RsTextarea.scss';

type Props = {
  id: string;
  label: string;
  icon: string;
  children?: React.ReactNode;
  info?: string;
  onChange?: Function;
} & FieldAttributes<any>;

export default function RsTextarea(props: Props) {
  const [field, meta] = useField(props);
  const { mode, loading: formLoading } = useContext(RsFormContext);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);

    if (props.onChange) {
      props.onChange(e);
    }
  };

  const alteredProps = { ...props, ...field };
  delete alteredProps.onChange;
  delete alteredProps.children;

  return (
    <Fragment>
      <div className={`rs-field ${meta.touched && meta.error && 'has-error'}`}>
        <div className="rs-field-content">
          <RsLabel {...props} />

          <div className="field-wrapper">
            {props.loading || formLoading ? (
              <RsLoading height="110px" />
            ) : (
              <>
                {mode === 'edit' ? (
                  <>
                    <Field
                      rows="4"
                      as="textarea"
                      id={props.id || props.name}
                      placeholder=" "
                      {...alteredProps}
                      onChange={onChange}
                    />

                    {props.children ? (
                      <RsInfo id={`${props.name || props.id}_${Math.random()}`} type="dark">
                        {props.children}
                      </RsInfo>
                    ) : (
                      ''
                    )}
                    {props.info && (
                      <RsInfo id={`${props.name}_${Math.random()}`} type="dark">
                        {props.info}
                      </RsInfo>
                    )}
                  </>
                ) : (
                  <Fragment>
                    <p className="text-area-view has-text-weight-bold">{field.value || '-'}</p>
                  </Fragment>
                )}
              </>
            )}
          </div>
        </div>
        {mode === 'edit' && <RsFieldError name={props.name} />}
      </div>
    </Fragment>
  );
}
