import { isString } from 'lodash';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import LottieComponent from '../components/lottie/LottieComponent';
import FooterComponent from '../layout/footer/FooterComponent';
import RsMainNavigation from '../layout/rs-main-navigation/RsMainNavigation';
import NotFoundPage from '../pages/404/NotFoundPage';
import AboutUsPage from '../pages/about-us/AboutUsPage';
import BankEidConfirmationPage from '../pages/auth/bank-eid-confirmation/BankEidConfirmationPage';
import LoginPage from '../pages/auth/login-page/LoginPage';
import LoginWithBankEid from '../pages/auth/login-with-bankid/LoginWithBankEid';
import ResendVerificationCodePage from '../pages/auth/resend-verification-code/ResendVerificationCodePage';
import ResetPasswordPage from '../pages/auth/reset-password/ResetPasswordPage';
import SignupPage from '../pages/auth/signup/SignupPage';
import VerifyAccountPage from '../pages/auth/verify-account-page/VerifyAccountPage';
import ContactPage from '../pages/contact/ContactPage';
import SomethingWentWrong from '../pages/error/SomethingWentWrong';
import HomePage from '../pages/home/HomePage';
import LoadingPage from '../pages/loading/LoadingPage';
import PrivacyPolicy from '../pages/privacy-policy/PrivacyPolicy';
import ProfessionalInvestorStatus from '../pages/professional-investor-status/ProfessionalInvestorStatus';
import { RealstocksServices } from '../pages/realstocks-services/RealstocksServices';
import TermsAndConditions from '../pages/terms-of-service/TermsOfService';
import { getLoading } from '../redux/redux-app/app-selectors';
import ClientPath from './ClientPath';
import { NavigationContext } from './NavigationContext';

const RsApplicationRoutesForCompany = lazy(() => import('./RsApplicationRoutesForCompany'));

type PropsT = { appInitialized: boolean };

export default function RsApplicationRoutes(props: PropsT) {
  const { appInitialized } = props;
  const [navigationLoading, setNavigationLoading] = useState<boolean>(!appInitialized);
  const loading = useSelector(getLoading);

  const mutateNavigationLoading = (loading: boolean) => {
    setNavigationLoading(loading);
  };

  useEffect(() => {
    setNavigationLoading(!appInitialized);
  }, [appInitialized]);

  return (
    <div className="rs-routes">
      <NavigationContext.Provider value={{ loading: navigationLoading, mutateNavigationLoading }}>
        <RsMainNavigation />

        {loading && <LottieComponent message={isString(loading) ? loading : 'Loading'} className="page-wide" />}

        <div className="rs-routes-content" style={{ display: loading ? 'none' : '' }}>
          <div className="rs-route">
            <Suspense fallback={<div></div>}>
              <Routes>
                <Route path={ClientPath.home} element={<HomePage />} />
                {/* <Route path={'how-it-works/*'} element={<HowItWorksPage />} /> */}
                <Route path={'services/*'} element={<RealstocksServices />} />
                <Route path={ClientPath.static.aboutUs} element={<AboutUsPage />} />
                <Route path={ClientPath.static.privacyPolicy} element={<PrivacyPolicy />} />
                <Route path={ClientPath.auth.login} element={<LoginPage initialized={appInitialized} />} />
                <Route
                  path={ClientPath.auth.loginWithBankid}
                  element={<LoginWithBankEid initialized={appInitialized} />}
                />
                <Route path={ClientPath.auth.signup} element={<SignupPage />} />
                <Route path={ClientPath.auth.forgotPassword} element={<ResetPasswordPage />} />
                <Route path={ClientPath.loading} element={<LoadingPage />} />
                <Route path={'/terms-of-service/*'} element={<TermsAndConditions />} />
                <Route path={ClientPath.static.professionalInvestorStatus} element={<ProfessionalInvestorStatus />} />
                <Route path={ClientPath.auth.resendVerificationCode} element={<ResendVerificationCodePage />} />
                <Route path={ClientPath.auth.verifyAccount} element={<VerifyAccountPage />} />
                <Route path={ClientPath.static.contact} element={<ContactPage />} />
                <Route path={ClientPath.auth.bankEidConfirmation} element={<BankEidConfirmationPage />} />

                <Route path={'/app/*'} element={<RsApplicationRoutesForCompany appInitialized={appInitialized} />} />

                <Route path={ClientPath.notFound} element={<NotFoundPage />} />
                <Route path={ClientPath.error.somethingWentWrong} element={<SomethingWentWrong />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Suspense>
          </div>
        </div>

        <FooterComponent className={loading ? 'loading' : ''} />
      </NavigationContext.Provider>
    </div>
  );
}
