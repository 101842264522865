import RsList from '../../../../../components/rs-elements/rs-list/RsList';
import { RsListItem } from '../../../../../components/rs-elements/rs-list/RsListItem';
import { getStaticMedia } from '../../../../../constants/Aws';

export default function AdministrationServiceImport() {
  const items: RsListItem[] = [
    new RsListItem('Via flat file').setItems([
      new RsListItem('Flexible data format & set of metadata'),
      new RsListItem('Optional history & ongoing updates of externally handled events (e.g. interest payments)'),
    ]),
    new RsListItem('Via user interface').setItems([
      new RsListItem('Single loans can be added manually to the platform'),
      new RsListItem('Scheduled events will run automatically'),
      new RsListItem('Manual updates possible at any time'),
      new RsListItem('Update feeds (e.g. account statements) can be sourced automatically'),
    ]),
  ];
  return (
    <div className="section is-grey">
      <h4 className="has-text-centered headline-underlined">
        IMPORT YOUR EXISTING LOAN PORTFOLIO OF ALREADY RUNNING LOANS
      </h4>

      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column is-6">
            <div className="img-container">
              <img src={getStaticMedia('/images/static-pages/administration_3.png')} />
            </div>
          </div>
          <div className="column list-container is-12-mobile is-6-desktop">
            <RsList items={items} />
          </div>
        </div>
      </div>
    </div>
  );
}
