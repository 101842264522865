import { API } from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { RsCognitoUser } from '@realstocks/types';
import axios, { AxiosRequestConfig } from 'axios';
import { FormikErrors } from 'formik';
import { toast } from 'react-toastify';
import { LocalStorageKeys } from '../../constants/LocalStorageKeys';
import { IDLE_TIME, ProcessEnv } from '../../constants/ProcessEnv';
import { RsError, ServerErrorResponse } from '../../constants/ServerError';

export type RsApiConfigType = AxiosRequestConfig & {
  headers?: any;
  // return the entire Axios response object instead of only response.data
  response?: true;
  responseType?: string;
  queryStringParameters?: RsApiQueryParams;
  body?: any;
};

export interface RsApiConfigInterface {
  headers?: any;
  response?: true;
  responseType?: string;
  queryStringParameters?: RsApiQueryParams;
  body?: any;
}

export type RsApiQueryParams = {
  [key: string]: any;
};

export interface RsApiParamsInterface {
  path: string;
  config?: RsApiConfigType;
}

const fireLocalStorageInactiveTime = (): void => {
  const event = new Event('LocalStorageInactiveTime');
  window.dispatchEvent(event);
};

export const writeInactiveTimeCookie = () => {
  const logoutDate = Number(new Date().valueOf()) + IDLE_TIME;
  localStorage.setItem(LocalStorageKeys.inactiveTime, String(logoutDate));
  fireLocalStorageInactiveTime();
};

/**
 * Add token authorization header and other required data for all requests
 *
 * @param params
 */
async function setRequiredHeaders(params: RsApiParamsInterface, external: boolean = false) {
  params.config = params.config ? params.config : {};
  params.config.headers = params.config.headers ? params.config.headers : {};

  if (!external) {
    try {
      const currentUser: RsCognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: false });
      params.config.headers.Authorization = currentUser.getSignInUserSession()?.getIdToken().getJwtToken();
    } catch (error) {}
  }

  return params;
}

export const rsApiFetcher = async (url: string | null, method: 'get' | 'post' | 'put' | 'delete', params?: any) => {
  try {
    if (!url) return false;

    const response = await RsApi[method]({
      path: url,
      config: params,
    });

    if (response && response.message && Object.keys(response).length === 1) {
      return response.message;
    }

    return response;
  } catch (err) {
    console.error(err);
    throw new RsError((err as any)?.data?.message || '');
  }
};

export const RsApi = {
  axiosGet: async (params: RsApiParamsInterface) => {
    params = await setRequiredHeaders(params, true);
    return executeApiRequest(async () => {
      return await axios.get(params.path, params.config);
    });
  },
  axiosPost: async (params: RsApiParamsInterface) => {
    params = await setRequiredHeaders(params, true);
    return executeApiRequest(async () => {
      return await axios.post(
        params.path,
        params.config && params.config.body ? params.config.body : '',
        params.config
      );
    });
  },
  get: async (params: RsApiParamsInterface) => {
    params = await setRequiredHeaders(params);
    return executeApiRequest(async () => {
      return await API.get(ProcessEnv.aws.api.name!, params.path, params.config!);
    });
  },
  post: async (params: RsApiParamsInterface) => {
    params = await setRequiredHeaders(params);
    return executeApiRequest(async () => {
      return await API.post(ProcessEnv.aws.api.name!, params.path, params.config!);
    });
  },
  put: async (params: RsApiParamsInterface) => {
    params = await setRequiredHeaders(params);
    return executeApiRequest(async () => {
      return await API.put(ProcessEnv.aws.api.name!, params.path, params.config!);
    });
  },
  delete: async (params: RsApiParamsInterface) => {
    params = await setRequiredHeaders(params);
    return executeApiRequest(async () => {
      return await API.del(ProcessEnv.aws.api.name!, params.path, params.config!);
    });
  },
};

async function executeApiRequest(func: () => Promise<any>) {
  try {
    return await func();
  } catch (error) {
    const e: any = error;
    if ((error as ServerErrorResponse).data && (error as ServerErrorResponse).data.message) {
      // handle generic responses
      // i.e. & TODO when the token expired, reset here.
    }

    throw e.response ? e.response : error;
  }
}

const mapErrorCode = (errorCode: string, errorVersionMessage?: string): string => {
  switch (errorCode) {
    case 'version-conflict':
      return errorVersionMessage || 'New version is available';
    default:
      return errorCode;
  }
};

const getFirstFormErrorMessage = (errors: FormikErrors<any>): string | null => {
  let temp: any = null;

  if (errors && Object.keys(errors).length > 0) {
    for (const fieldName of Object.keys(errors)) {
      temp = errors[fieldName];
    }
  }

  // some errors have keys (for example if a field name is a.x.z then the error for that field is under a.x.z)
  // traverse the object deep and get the error message only.
  let objectTraversDone = false;

  while (!objectTraversDone) {
    if (temp !== null && typeof temp === 'object') {
      const firstKey: string = Object.keys(temp)[0];
      temp = temp[firstKey];
    } else {
      objectTraversDone = true;
    }
  }
  return typeof temp === 'string' ? temp : null;
};

export const getErrorMessageRaw = (error: any) => {
  if (error?.message && typeof error.message === 'string') {
    return error.message;
  }

  if (error?.data?.message && typeof error.data.message === 'string') {
    return error.data.message;
  }

  if (error?.data?.errors) {
    const firstErrorMessage = getFirstFormErrorMessage(error.data.errors);
    toast.error(firstErrorMessage || 'Please check the form for errors');
  }

  if (error?.data && typeof error.data === 'string') {
    return error.data;
  }

  return 'Something went wrong';
};

export const getErrorMessage = (error: any, errorVersionMessage?: string): string => {
  const message = getErrorMessageRaw(error);
  const final = mapErrorCode(message, errorVersionMessage);
  return final;
};

export const getErrorCode = (error: any): string => {
  const message = error?.data?.message || error?.data;

  if (!message || (message && typeof message !== 'string')) {
    return 'Something went wrong';
  }

  return message;
};

export const handleApiError = (error: any, errorVersionMessage?: string, refetch?: Function) => {
  const errorCode = getErrorCode(error);
  const errorMessage = getErrorMessage(error, errorVersionMessage);

  if (refetch && errorCode === 'version-conflict') {
    refetch();
  }

  toast.error(errorMessage);
};
