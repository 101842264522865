import { compact } from 'lodash';
import { numberLocales } from '../config/number.config';
import {
  AmortisationBaseDetailsType,
  AmortisationPaymentDetailsType,
  BookingType,
  CouponAgentFeeBooking,
  DirectCouponBookingType,
  IndirectCouponBookingType,
  InterestCapitalisationDetails,
  LateTransactionPaymentDetailsType,
  PayingAgentCouponBookingType,
  PayOutDrawdownAgentFeeBooking,
  PayOutDrawdownBooking,
  PayOutPrincipalAgentFeeBooking,
  PayOutPrincipalBookingType,
} from '../loan/BookingType';
import { CapTableShareType } from '../loan/CapTableShareType';
import { InterestPaymentDetailsType, InterestRateInterestPaymentDetails } from '../loan/InterestPaymentDetailsType';
import { InterestRateAdjustmentType } from '../loan/InterestRateAdjustmentType';
import { InterestRateDetailsType, SubventionRateDetailsType } from '../loan/InterestRateDetailsType';
import { FloatingInterestRateType } from '../loan/InterestRateType';
import { InterestAndSubventionShare, SubperiodShares } from '../loan/SubperiodShares';
import { numberFormatter } from './number.formatter';

export type InvoicePositionList = {
  positions: InvoicePosition[];
  total: number;
};

export type InvoicePositionName = string;

export type InvoicePosition = {
  name: InvoicePositionName;
  calculation: string;
  amount: number;
};

export function bookingToInvoicePositionList(booking: BookingType): InvoicePositionList {
  return {
    positions: bookingToPositions(booking),
    total: booking.amount,
  };
}

export function bookingToPositions(booking: BookingType): InvoicePosition[] {
  const positions = bookingToPositionsWithoutCustomAmount(booking);
  const customAmount = booking['custom-amount'];
  if (customAmount === undefined) return positions;
  const customPosition: InvoicePosition = {
    name: 'Ad-hoc payment',
    calculation: booking.details['set-custom-booking-amount-details']?.description ?? '',
    amount: customAmount,
  };
  return [...positions, customPosition];
}

function bookingToPositionsWithoutCustomAmount(booking: BookingType): InvoicePosition[] {
  switch (booking.type) {
    case 'pay-out-principal': {
      return [
        // share-amount
        ...sharePositionList('Principal', 'Total', booking.details.share),

        // arrangement-fee-amount
        ...arrangementFeePositionList(booking),

        // commitment-fee-amount
        ...commitmentFeePositionList(booking),

        // drawdown-fee-amount
        ...drawdownFeePositionList(booking),
      ];
    }
    case 'pay-out-principal-agent-fee': {
      return [
        // arrangement-fee-amount
        ...arrangementFeePositionList(booking),

        // commitment-fee-amount
        ...commitmentFeePositionList(booking),

        // drawdown-fee-amount
        ...drawdownFeePositionList(booking),
      ];
    }
    case 'pay-out-drawdown': {
      return [
        // share-amount
        {
          name: 'Drawdown',
          calculation: '', // No calculation - the amount is entered by the user
          amount: booking['share-amount'],
        },

        // drawdown-fee-amount
        ...drawdownFeePositionList(booking),
      ];
    }
    case 'pay-out-drawdown-agent-fee': {
      return [
        // drawdown-fee-amount
        ...drawdownFeePositionList(booking),
      ];
    }
    case 'coupon-agent-fee': {
      return [
        // interest-fee-amount
        ...interestFeePositionList(booking),

        // commitment-fee-amount
        ...commitmentFeePositionList(booking),
      ];
    }
    case 'direct-coupon': {
      return [
        // amortisation-amount
        ...amortisationPositionList(
          booking,
          booking.details['amortisation-payment-details'],
          booking.details['amortisation-share']
        ),

        // interest-amount
        ...interestPositionList(booking.details['interest-payment-details'], booking.details['subperiod-shares']),

        // subvention-amount
        ...subventionPositionList(
          booking.details['interest-payment-details'],
          booking.details['subperiod-shares'],
          true
        ),

        // late-fee-amount
        ...lateFeePositionList(
          booking,
          booking.details['late-transaction-payment-details'],
          booking.details['late-fee-share']
        ),

        // commitment-fee-amount
        ...commitmentFeePositionList(booking),

        // interest-capitalisation-amount
        ...interestCapitalisationPositionList(
          booking,
          booking.details['interest-capitalisation-details'],
          booking.details['interest-capitalisation-share']
        ),
      ];
    }
    case 'direct-coupon-subvention': {
      return subventionPositionList(
        booking.details['interest-payment-details'],
        booking.details['subperiod-shares'],
        false
      );
    }
    case 'direct-subperiod-prepayment':
    case 'direct-prepayment': {
      return [
        ...sharePositionList('Amortisation', 'Unscheduled amortisation', booking.details['amortisation-share']),
        ...sharePositionList('Prepayment fee', 'Total', booking.details['fee-share']),
      ];
    }
    case 'direct-pay-back-principal': {
      return sharePositionList('Principal', 'Total', booking.details.share);
    }
    case 'processing-fee-coupon':
      break;
    case 'processing-fee-pay-back-principal':
      break;
    case 'processing-fee-subperiod-prepayment':
    case 'processing-fee-prepayment':
      break;
    case 'tax-withholding-coupon':
      break;
    case 'tax-withholding-subperiod-prepayment':
    case 'tax-withholding-prepayment':
      break;
    case 'indirect-coupon': {
      const lenderTax = booking.details['lender-tax'];
      return [
        // interest-amount
        ...interestSubperiodSharesList(booking.details['subperiod-shares']),

        // amortisation-amount
        ...sharePositionList('Amortisation', 'Total amortisation', booking.details['amortisation-share']),

        // late-fee-amount
        ...sharePositionList('Late Payment Interest', 'Total late payment interest', booking.details['late-fee-share']),

        // commitment-fee-amount
        ...commitmentFeePositionList(booking),

        // interest-capitalisation-amount
        ...sharePositionList(
          'Interest Capitalisation',
          'Total interest capitalisation',
          booking.details['interest-capitalisation-share']
        ),

        // fee-interest-amount
        ...sharePositionList(
          'Interest Processing Fee',
          'Total interest processing fee',
          booking.details['fee-interest-share']
        ),

        // fee-late-fee-amount
        ...sharePositionList(
          'Late Fee Processing Fee',
          'Total late fee processing fee',
          booking.details['fee-late-fee-share']
        ),

        // fee-commitment-fee-amount
        ...sharePositionList(
          'Commitment Fee Processing Fee',
          'Total commitment fee processing fee',
          booking.details['fee-commitment-fee-share']
        ),

        // fee-amortisation-amount
        ...sharePositionList(
          'Amortisation Processing Fee',
          'Total amortisation processing fee',
          booking.details['fee-amortisation-share']
        ),

        // fee-interest-capitalisation-amount
        ...sharePositionList(
          'Interest Capitalisation Processing Fee',
          'Total interest capitalisation processing fee',
          booking.details['fee-interest-capitalisation-share']
        ),

        // tax-interest-amount
        ...taxPositionList({
          name: 'Interest Tax',
          amount: booking['tax-interest-amount'],
          totalName: 'Interest',
          totalAmount: booking['interest-amount'],
          lenderTax,
        }),

        // tax-late-fee-amount
        ...taxPositionList({
          name: 'Late Fee Tax',
          amount: booking['tax-late-fee-amount'],
          totalName: 'Late fee',
          totalAmount: booking['late-fee-amount'],
          lenderTax,
        }),

        // tax-commitment-fee-amount
        ...taxPositionList({
          name: 'Commitment Fee Tax',
          amount: booking['tax-commitment-fee-amount'],
          totalName: 'Commitment fee',
          totalAmount: booking['commitment-fee-amount'],
          lenderTax,
        }),

        // tax-interest-capitalisation-amount
        ...taxPositionList({
          name: 'Interest Capitalisation Tax',
          amount: booking['tax-interest-capitalisation-amount'],
          totalName: 'Interest capitalisation',
          totalAmount: booking['interest-capitalisation-amount'],
          lenderTax,
        }),

        // tax-fee-interest-amount
        ...taxPositionList({
          name: 'Interest Processing Fee Tax',
          amount: booking['tax-fee-interest-amount'],
          totalName: 'Interest Processing Fee',
          totalAmount: booking['fee-interest-amount'],
          lenderTax,
        }),

        // tax-fee-late-fee-amount
        ...taxPositionList({
          name: 'Late Fee Processing Fee Tax',
          amount: booking['tax-fee-late-fee-amount'],
          totalName: 'Late fee processing fee',
          totalAmount: booking['fee-late-fee-amount'],
          lenderTax,
        }),

        // tax-fee-commitment-fee-amount
        ...taxPositionList({
          name: 'Commitment Fee Processing Fee Tax',
          amount: booking['tax-fee-commitment-fee-amount'],
          totalName: 'Commitment fee processing fee',
          totalAmount: booking['fee-commitment-fee-amount'],
          lenderTax,
        }),

        // tax-fee-interest-capitalisation-amount
        ...taxPositionList({
          name: 'Interest Capitalisation Processing Fee Tax',
          amount: booking['tax-fee-interest-capitalisation-amount'],
          totalName: 'Interest capitalisation processing fee',
          totalAmount: booking['fee-interest-capitalisation-amount'],
          lenderTax,
        }),
      ];
    }
    case 'indirect-pay-back-principal':
      break;
    case 'indirect-subperiod-prepayment':
    case 'indirect-prepayment':
      break;
    case 'paying-agent-coupon':
      return [
        // interest-amount
        ...interestPositionList(booking.details['interest-payment-details'], undefined),

        // amortisation-amount
        ...amortisationPositionList(booking, booking.details['amortisation-payment-details'], undefined),

        // subvention-amount
        ...subventionPositionList(booking.details['interest-payment-details'], undefined, true),

        // late-fee-amount
        ...lateFeePositionList(booking, booking.details['late-transaction-payment-details'], undefined),

        // commitment-fee-amount
        ...commitmentFeePositionList(booking),

        // interest-capitalisation-amount
        ...interestCapitalisationPositionList(booking, booking.details['interest-capitalisation-details'], undefined),
      ];
    case 'paying-agent-coupon-subvention': {
      return subventionPositionList(booking.details['interest-payment-details'], undefined, false);
    }
    case 'paying-agent-pay-back-principal': {
      return [
        {
          name: 'Principal',
          calculation: '',
          amount: booking.amount,
        },
      ];
    }
    case 'paying-agent-subperiod-prepayment':
    case 'paying-agent-prepayment': {
      return [
        {
          name: 'Amortisation',
          calculation: 'Unscheduled amortisation',
          amount: booking['amortisation-amount'],
        },
        {
          name: 'Prepayment fee',
          calculation: '', // The LCE knows nothing about how the fee was computed.
          amount: booking['fee-amount'],
        },
      ];
    }
  }
  return [
    {
      name: 'Amount',
      calculation: '',
      amount: booking.amount,
    },
  ];
}

function arrangementFeePositionList(
  booking: PayOutPrincipalBookingType | PayOutPrincipalAgentFeeBooking
): InvoicePosition[] {
  const amount = booking['arrangement-fee-amount'];
  const { details } = booking;
  const fee = details['arrangement-fee']?.fee;
  const feeDetails = details['arrangement-fee-details'];
  if (amount === undefined || fee === undefined || feeDetails === undefined) return [];

  const share = 'arrangement-fee-share' in details ? details['arrangement-fee-share'] : undefined;
  const shareFactorOperator = shareFactorOperatorText(share);
  const sign = booking.type === 'pay-out-principal' ? '- ' : '';
  return [
    {
      name: 'Arrangement fee',
      calculation: `${sign}Fee ${fractionText(fee)} * Commitment amount ${amountText(
        feeDetails['commitment-size']
      )}${shareFactorOperator}`,
      amount,
    },
  ];
}

function drawdownFeePositionList(
  booking:
    | PayOutPrincipalBookingType
    | PayOutPrincipalAgentFeeBooking
    | PayOutDrawdownBooking
    | PayOutDrawdownAgentFeeBooking
): InvoicePosition[] {
  const amount = booking['drawdown-fee-amount'];
  const { details } = booking;
  const feeAmount = details['drawdown-fee']?.['fee-amount'];
  if (amount === undefined || feeAmount === undefined) return [];

  const share = 'drawdown-fee-share' in details ? details['drawdown-fee-share'] : undefined;
  const shareFactorOperator = shareFactorOperatorText(share);
  return [
    {
      name: 'Drawdown fee',
      calculation: share ? `- Total fee ${amountText(feeAmount)}${shareFactorOperator}` : '',
      amount,
    },
  ];
}

function interestFeePositionList(booking: CouponAgentFeeBooking): InvoicePosition[] {
  const interestFee = booking.details['interest-fee'];
  const interestFeeDetails = booking.details['interest-fee-details'];
  if (interestFee === undefined || interestFeeDetails === undefined) return [];

  const interestPaymentDetails = interestFeeDetails['interest-payment-details'];
  if (interestPaymentDetails.type === 'mortgage-bond') return [];

  const rate = fractionText(interestFee.fee);
  const { length } = interestPaymentDetails.subperiods;
  return interestPaymentDetails.subperiods
    .map((subperiod, index) => {
      const amount = subperiod['interest-fee-amount'];
      if (amount === undefined) return undefined;
      return {
        name: subperiodName('Agent fee', index, length),
        calculation: interestText(
          rate,
          subperiod.subperiod['outstanding-debt'],
          subperiod['coupon-factor-details'],
          undefined
        ),
        amount,
      };
    })
    .filter((position): position is InvoicePosition => position !== undefined);
}

function commitmentFeePositionList(
  booking:
    | PayOutPrincipalBookingType
    | PayOutPrincipalAgentFeeBooking
    | PayingAgentCouponBookingType
    | CouponAgentFeeBooking
    | DirectCouponBookingType
    | IndirectCouponBookingType
): InvoicePosition[] {
  const feeDetails = booking.details['commitment-fee-details'];
  if (feeDetails === undefined) return [];

  const sign = booking.type === 'pay-out-principal' ? -1 : 1;
  const signText = sign < 0 ? '- ' : '';
  const { length } = feeDetails.subperiods;

  return feeDetails.subperiods.map((sub, index) => {
    const shareFactorOperator = shareFactorOperatorText(sub.share);
    return {
      name: subperiodName('Commitment fee', index, length),
      calculation: `${signText}Fee ${fractionText(sub.fee)} * Undrawn commitment ${amountText(
        sub['available-commitment-amount']
      )} * ${periodDaysText(sub)}${shareFactorOperator}`,
      amount: sign * (sub.share?.amount ?? sub['commitment-fee-amount']),
    };
  });
}

function taxPositionList(args: {
  name: InvoicePositionName;
  amount: number | undefined;
  totalName: string;
  totalAmount: number | undefined;
  lenderTax: number | undefined;
}): InvoicePosition[] {
  const { name, amount, totalName, totalAmount, lenderTax } = args;
  if (amount !== undefined && totalAmount !== undefined && lenderTax !== undefined) {
    return [
      {
        name,
        calculation: `- ${totalName} ${amountText(totalAmount)} * tax ${fractionText(lenderTax)}`,
        amount,
      },
    ];
  }
  return [];
}

function interestSubperiodSharesList(subperiodShares: SubperiodShares): InvoicePosition[] {
  const { shares } = subperiodShares;

  const shareList = (name: string, totalName: string, share: InterestAndSubventionShare) =>
    sharePositionList(name, totalName, share['interest-share']);

  if (shares.length === 1) return shareList('Interest', 'Total interest', shares[0]);

  return subperiodShares.shares.flatMap((share, index) =>
    shareList(`Interest ${index + 1}`, 'Subperiod interest', share)
  );
}

function sharePositionList(
  name: InvoicePositionName,
  totalName: string,
  share: CapTableShareType | undefined
): InvoicePosition[] {
  if (!share) return [];

  const total = amountText(share['total-amount']);
  const shareFactorOperator = shareFactorOperatorText(share);
  return [
    {
      name,
      calculation: `${totalName} ${total}${shareFactorOperator}`,
      amount: share.amount,
    },
  ];
}

function amortisationPositionList(
  booking: { 'amortisation-amount': number },
  amortisationPaymentDetails: AmortisationPaymentDetailsType | undefined,
  amortisationShare: CapTableShareType | undefined
): InvoicePosition[] {
  if (!amortisationPaymentDetails) return [];
  return [
    {
      name: 'Amortisation',
      calculation: amortisationText(amortisationPaymentDetails, amortisationShare),
      amount: booking['amortisation-amount'],
    },
  ];
}

function subventionPositionList(
  interestPaymentDetails: InterestPaymentDetailsType,
  subperiodShares: SubperiodShares | undefined,
  invert: boolean
): InvoicePosition[] {
  if (interestPaymentDetails.type !== 'interest-rate') return [];

  const subventionRateDetails = interestPaymentDetails['interest-rate-details']['subvention-rate-details'];
  if (subventionRateDetails === undefined) return [];

  const subventionRate = subventionRateText(interestPaymentDetails, subventionRateDetails, invert);
  const { length } = interestPaymentDetails.subperiods;
  const positions = interestPaymentDetails.subperiods.map((subperiod, index) => {
    const share = subperiodShares?.shares[index]['subvention-share'];
    const amount = share ? share.amount : subperiod['subvention-details']?.['subvention-amount'];
    if (amount === undefined) return undefined;
    return {
      name: subperiodName('Government subvention', index, length),
      calculation: interestText(
        subventionRate,
        subperiod.subperiod['outstanding-debt'],
        subperiod['coupon-factor-details'],
        share
      ),
      amount: invert ? -amount : amount,
    };
  });
  return compact(positions);
}

function interestPositionList(
  interestPaymentDetails: InterestPaymentDetailsType,
  subperiodShares: SubperiodShares | undefined
): InvoicePosition[] {
  switch (interestPaymentDetails.type) {
    case 'interest-rate': {
      const interestRate = interestRateText(interestPaymentDetails['interest-rate-details']);
      const { length } = interestPaymentDetails.subperiods;
      return interestPaymentDetails.subperiods.map((subperiod, index) => {
        const share = subperiodShares?.shares[index]['interest-share']!;
        const amount = share ? share.amount : subperiod['interest-amount'];
        return {
          name: subperiodName('Interest', index, length),
          calculation: interestText(
            interestRate,
            subperiod.subperiod['outstanding-debt'],
            subperiod['coupon-factor-details'],
            share
          ),
          amount,
        };
      });
    }
    case 'mortgage-bond': {
      return [
        {
          name: 'Interest',
          calculation: 'Mortgage bond',
          amount: interestPaymentDetails.amount,
        },
      ];
    }
  }
}

function lateFeePositionList(
  booking: { 'late-fee-amount'?: number },
  lateDetails: LateTransactionPaymentDetailsType | undefined,
  lateFeeShare: CapTableShareType | undefined
): InvoicePosition[] {
  const lateFeeAmount = booking['late-fee-amount'];
  if (lateFeeAmount !== undefined && lateDetails) {
    const baseAmount = amountText(lateDetails['base-amount']);
    const rate = fractionText(lateDetails.rate);
    const periodDays = periodDaysText(lateDetails);
    const shareFactorOperator = shareFactorOperatorText(lateFeeShare);
    return [
      {
        name: 'Late Payment Interest',
        calculation: `Amount ${baseAmount} * late payment i.r. ${rate} * ${periodDays}${shareFactorOperator}`,
        amount: lateFeeAmount,
      },
    ];
  }
  return [];
}

function interestCapitalisationPositionList(
  booking: { 'interest-capitalisation-amount'?: number },
  details: InterestCapitalisationDetails | undefined,
  share: CapTableShareType | undefined
): InvoicePosition[] {
  const interestCapitalisationAmount = booking['interest-capitalisation-amount'];
  if (interestCapitalisationAmount === undefined || !details) return [];

  const { fraction } = details['split-details'];
  const capitalisation = `Capitalisation ${fractionText(fraction)}`;
  const base = interestCapitalisationBaseText(details);
  const shareFactorOperator = shareFactorOperatorText(share);
  return [
    {
      name: 'Interest Capitalisation',
      calculation: `- ${base} * ${capitalisation}${shareFactorOperator}`,
      amount: interestCapitalisationAmount,
    },
  ];
}

function interestCapitalisationBaseText(interestCapitalisationDetails: InterestCapitalisationDetails): string {
  const interestAmount = interestCapitalisationDetails['interest-amount'];
  const subventionAmount = interestCapitalisationDetails['subvention-amount'];

  const interestText = `Interest ${amountText(interestAmount)}`;
  if (subventionAmount === undefined) return interestText;

  const subventionText = `Subvention ${amountText(subventionAmount)}`;
  return `(${interestText} - ${subventionText})`;
}

function amortisationText(
  amortisationPaymentDetails: AmortisationPaymentDetailsType,
  amortisationShare: CapTableShareType | undefined
): string {
  switch (amortisationPaymentDetails.type) {
    case 'custom':
      return 'Scheduled cf. loan Agreement';
    case 'mortgage-bond':
      return 'Mortgage bond';
    case 'period': {
      const rate = fractionText(amortisationPaymentDetails.rate);
      const baseAmount = baseAmountText(amortisationPaymentDetails['base-details']);
      const periodDays = periodDaysText(amortisationPaymentDetails['coupon-factor-details']);
      const shareFactorOperator = shareFactorOperatorText(amortisationShare);
      return `Amortisation rate ${rate} per annum * ${baseAmount} * ${periodDays}${shareFactorOperator}`;
    }
    case 'constant-period': {
      const rate = fractionText(amortisationPaymentDetails.rate);
      const baseAmount = baseAmountText(amortisationPaymentDetails['base-details']);
      const shareFactorOperator = shareFactorOperatorText(amortisationShare);
      return `Amortisation rate ${rate} per period * ${baseAmount}${shareFactorOperator}`;
    }
  }
}

function baseAmountText(base: AmortisationBaseDetailsType): string {
  const label = base.type === 'loan-size' ? 'Utilisation amount' : 'Outstanding amount';
  const amount = amountText(base.amount);
  return `${label} ${amount}`;
}

function interestText(
  interestRate: string,
  outstandingDebt: number,
  couponFactor: { 'days-in-period': number; 'days-in-year': number },
  interestShare: CapTableShareType | undefined
): string {
  const shareFactorOperator = shareFactorOperatorText(interestShare);
  const periodDays = periodDaysText(couponFactor);
  return `${interestRate} * ${outstandingDebtText(outstandingDebt)} * ${periodDays}${shareFactorOperator}`;
}

function shareFactorOperatorText(share: CapTableShareType | undefined): string {
  if (!share) return '';
  const tokens = share['number-of-tokens'];
  const totalTokens = share['total-number-of-tokens'];
  if (tokens === totalTokens) return '';
  return ` * Share of loan ${fractionText(tokens / totalTokens)}`;
}

function outstandingDebtText(debt: number): string {
  return `Outstanding amount ${amountText(debt)}`;
}

function subventionRateText(
  interestPaymentDetails: InterestRateInterestPaymentDetails,
  subventionRateDetails: SubventionRateDetailsType,
  invert: boolean
): string {
  const total = fractionText(interestPaymentDetails['interest-rate-details'].rate);
  const threshold = fractionText(subventionRateDetails.threshold);
  // Business rule dictates we should show all the decimals of the subvention rate
  const coverage = allDecimalsPercentage.format(subventionRateDetails.coverage);
  const base = `(Total interest rate ${total} * - Threshold ${threshold})`;
  return `${invertText(invert)}${base} * share of subsidy ${coverage}`;
}

const invertText = (invert: boolean) => (invert ? '- ' : '');

export function interestRateText(details: InterestRateDetailsType): string {
  const interestRateAdjustmentOperator = interestRateAdjustmentOperatorText(details['interest-rate-adjustment']);

  switch (details.type) {
    case 'fixed': {
      const rate = fractionText(details['unadjusted-rate']);
      return `Fixed rate ${rate}${interestRateAdjustmentOperator}`;
    }
    case 'floating': {
      const rate = fractionText(details.rate);
      const baseRate = fractionText(details['base-rate'].rate);
      const margin = fractionText(details['interest-rate'].margin);
      const cappingText = baseRateCappingText(details['interest-rate']);
      return `All-in interest ${rate} (Reference rate ${baseRate}${cappingText}${interestRateAdjustmentOperator} + Margin ${margin})`;
    }
    case 'custom': {
      const rate = fractionText(details['unadjusted-rate']);
      return `Custom rate ${rate}${interestRateAdjustmentOperator}`;
    }
  }
}

export function baseRateCappingText(interestRate: FloatingInterestRateType): string {
  const body = compact([baseRateCappingFloorText(interestRate), baseRateCappingCeilingText(interestRate)]).join(', ');
  if (body === '') return '';
  else return ` (${body})`;
}

function baseRateCappingFloorText(interestRate: FloatingInterestRateType): string | undefined {
  const floor = interestRate['base-rate-floor'];
  if (floor !== undefined) return floor === 0 ? 'floored' : `floored to ${fractionText(floor)}`;
  if (interestRate['base-rate-floored']) return 'floored';
  return undefined;
}

function baseRateCappingCeilingText(interestRate: FloatingInterestRateType): string | undefined {
  const ceiling = interestRate['base-rate-ceiling'];
  if (ceiling !== undefined) return `capped to ${fractionText(ceiling)}`;
  return undefined;
}

function interestRateAdjustmentOperatorText(obj: InterestRateAdjustmentType | undefined): string {
  if (obj === undefined || obj.adjustment === 0) return '';
  const sign = obj.adjustment < 0 ? '-' : '+';
  return ` ${sign} Adjustment ${fractionText(Math.abs(obj.adjustment))}`;
}

function periodDaysText(couponFactor: { 'days-in-period': number; 'days-in-year': number }): string {
  const days = couponFactor['days-in-period'];
  const daysInYear = couponFactor['days-in-year'];
  return `number of days ${days}/${daysInYear}`;
}

const formatFractionAsPercent = Intl.NumberFormat(numberLocales, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 3,
  style: 'percent',
});

const allDecimalsPercentage = Intl.NumberFormat(numberLocales, {
  minimumFractionDigits: 0,
  // 20 is maximum, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#maximumfractiondigits
  maximumFractionDigits: 20,
  style: 'percent',
});

const fractionText = (value: number) => formatFractionAsPercent.format(value);

const amountText = (value: number) => numberFormatter.amount.twoDecimals.format(value);

const subperiodName = (prefix: string, index: number, length: number): string =>
  length === 1 ? prefix : `${prefix} ${index + 1}`;
