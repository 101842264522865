import { CompanyConfigurations } from '@realstocks/types';
import { Dispatch } from 'redux';
import {
  CompanyDocumentsType,
  CompanyInformationType,
  CompanyKycStatusType,
  CompanyModel,
  RepresentativeType,
  UboType,
} from '../../types/Company';
import { ReduxAction } from '../redux';

export const CompanyActions = {
  setCompanyInformation: (companyInformation: CompanyInformationType) => {
    return { type: COMPANY_ACTIONS.setCompanyInformation, payload: companyInformation };
  },
  setCompanyConfigurations: (companyConfigurations: CompanyConfigurations | undefined) => {
    return { type: COMPANY_ACTIONS.setCompanyConfigurations, payload: companyConfigurations };
  },
  setRepresentatives: (representatives: RepresentativeType[]) => {
    return { type: COMPANY_ACTIONS.setRepresentatives, payload: representatives };
  },
  setUbo: (ubos: UboType[] | null) => {
    return { type: COMPANY_ACTIONS.setUBO, payload: ubos };
  },
  setDocuments: (documents: CompanyDocumentsType) => {
    return { type: COMPANY_ACTIONS.setDocuments, payload: documents };
  },
  setCompanyID: (id: string) => {
    return { type: COMPANY_ACTIONS.setId, payload: id };
  },
  setCompanyCompleted: (isCompleted: boolean) => {
    return { type: COMPANY_ACTIONS.setCompleted, payload: isCompleted };
  },
  setCompanyFetched: (isFetched: boolean) => {
    return { type: COMPANY_ACTIONS.setFetched, payload: isFetched };
  },
  setCompanyPrefilledData: (data: any) => {
    return { type: COMPANY_ACTIONS.setPrefilledData, payload: data };
  },
  setKycStatus: (data: CompanyKycStatusType) => {
    return { type: COMPANY_ACTIONS.setKycStatus, payload: data };
  },
  setExists: (exists: boolean | null) => {
    return { type: COMPANY_ACTIONS.setExists, payload: exists };
  },
  reset: () => {
    return { type: COMPANY_ACTIONS.reset };
  },
  setCreatedAt: (createdAt: number | null) => {
    return { type: COMPANY_ACTIONS.setCreatedAt, payload: createdAt };
  },
};

/**
 * Sets the company kyc status
 *
 * @param {CompanyKycStatusType} status: CompanyKycStatusType
 */
export function setKycStatus(status: CompanyKycStatusType) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(CompanyActions.setKycStatus(status));
  };
}

/**
 * Sets the company information
 *
 * @param {CompanyInformationType} companyInformation: CompanyInformationType
 */
export function setCompanyInformation(companyInformation: CompanyInformationType) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(CompanyActions.setCompanyInformation(companyInformation));
  };
}

/**
 * Sets the company configurations
 *
 * @param {CompanyInformationType} companyInformation: CompanyInformationType
 */
export function setCompanyConfigurations(companyConfigurations: CompanyConfigurations | undefined) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(CompanyActions.setCompanyConfigurations(companyConfigurations));
  };
}

/**
 * Sets the company representatives
 *
 * @param {RepresentativeType[]} representatives: RepresentativeType[]
 */
export function setRepresentatives(representatives: RepresentativeType[]) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(CompanyActions.setRepresentatives(representatives));
  };
}

/**
 * Sets the company ubo
 *
 * @param {UboType[]} ubos: UboType[]
 */
export function setUbo(ubos: UboType[]) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(CompanyActions.setUbo(ubos));
  };
}

/**
 * Sets the company documents
 *
 * @param {CompanyDocumentsType} documents: CompanyDocumentsType
 */
export function setDocuments(documents: CompanyDocumentsType) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(CompanyActions.setDocuments(documents));
  };
}

/**
 * Sets the company ID
 *
 * @param {string} id: string
 */
export function setCompanyID(id: string) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(CompanyActions.setCompanyID(id));
  };
}

/**
 * Sets the company created at
 *
 * @param {string} id: string
 */
export function setCreatedAt(createdAt: number | null) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(CompanyActions.setCreatedAt(createdAt));
  };
}

/**
 * Sets the company completed flag
 *
 * @param {boolean} isCompleted: boolean
 */
export function setCompanyCompleted(isCompleted: boolean) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(CompanyActions.setCompanyCompleted(isCompleted));
  };
}

/**
 * Sets the company request done
 *
 * @param {boolean} isCompleted: boolean
 */
export function setCompanyFetched(isFetched: boolean) {
  return CompanyActions.setCompanyFetched(isFetched);
}

/**
 * Sets the company exists status
 *
 * @param {boolean} exists: boolean | null
 */
export function setCompanyExists(exists: boolean | null) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(CompanyActions.setExists(exists));
  };
}

/**
 * Sets the company prefilled data saved from direct+ into company's step1 onboarding process
 *
 * @param {boolean} isCompleted: boolean
 */
export function setCompanyPrefilledData(data: any) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(CompanyActions.setCompanyPrefilledData(data));
  };
}

export function resetCompanyRedux() {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(CompanyActions.reset);
  };
}

export const setCompanyModel = (company: CompanyModel | null) => ({
  type: COMPANY_ACTIONS.setCompany,
  payload: company,
});

/**
 * App related actions
 */
export const COMPANY_ACTIONS = {
  setCompany: 'SET_COMPANY',
  setCompanyInformation: 'SET_COMPANY_INFORMATION',
  setCompanyConfigurations: 'SET_COMPANY_CONFIGURATIONS',
  setRepresentatives: 'SET_COMPANY_REPRESENTATIVES',
  setUBO: 'SET_COMPANY_UBO',
  setPEP: 'SET_COMPANY_PEP',
  setDocuments: 'SET_COMPANY_DOCUMENTS',
  setId: 'SET_COMPANY_ID',
  setCompleted: 'SET_COMPANY_COMPLETED',
  setFetched: 'SET_COMPANY_FETCHED',
  setPrefilledData: 'SET_PREFILLED_DATA',
  setKycStatus: 'SET_KYC_STATUS',
  setExists: 'SET_COMPANY_EXISTS',
  reset: 'RESET',
  setRoles: 'SET_ROLES',
  setCreatedAt: 'CREATED_AT',
};
