import { Dispatch } from 'redux';
import { ReduxAction } from '../redux';
import { LoadingStateType } from './app-selectors';

export const AppActions = {
  setLoading: (opened: LoadingStateType) => {
    return { type: APP_ACTIONS_KEYS.setLoading, payload: opened };
  },
  setEuribor: (euribor: number) => {
    return { type: APP_ACTIONS_KEYS.setEuribor, payload: euribor };
  },
  setSocketInstance: (socketInstance: WebSocket) => {
    return { type: APP_ACTIONS_KEYS.setSocketInstance, payload: socketInstance };
  },
  closeSocketInstance: () => {
    return { type: APP_ACTIONS_KEYS.closeSocketInstance };
  },
  setInitialized: (initialized: boolean) => {
    return { type: APP_ACTIONS_KEYS.setInitialized, payload: initialized };
  },
  setSyncTimestamp: (timestamp: number) => {
    return { type: APP_ACTIONS_KEYS.setSyncTimestamp, payload: timestamp };
  },
};

/**
 * Set loading template on/off
 *
 * @param {boolean} opened: boolean
 */
export function setLoading(opened: LoadingStateType) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(AppActions.setLoading(opened));
  };
}

/**
 * Set euribor
 *
 * @param {any[]} currencies: boolean
 */
export function setEuribor(euribor: number) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(AppActions.setEuribor(euribor));
  };
}

/**
 * Set socket instance
 *
 * @param {any[]} socketInstance: Websocket
 */
export function setSocketInstance(socketInstance: WebSocket) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(AppActions.setSocketInstance(socketInstance));
  };
}

/**
 * Close socket instance
 *
 * @param
 */
export function closeSocketInstance() {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(AppActions.closeSocketInstance());
  };
}

/**
 * Sync all state between tabs and force refresh
 *
 * @param
 */
export function syncBetweenTabs() {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(AppActions.setSyncTimestamp(Date.now()));
  };
}

/**
 * App related actions
 */
export const APP_ACTIONS_KEYS = {
  setLoading: 'SET_LOADING',
  setEuribor: 'SET_EURIBOR',
  setSocketInstance: 'SET_SOCKET_INSTANCE',
  closeSocketInstance: 'CLOSE_SOCKET_INSTANCE',
  setSyncTimestamp: 'SET_SYNC_TIMESTAMP',
  setInitialized: 'SET_INITIALIZED_APP',
};
