import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { connect, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Middleware, SWRConfig, SWRHook } from 'swr';
import InactivityManager from './components/inactivity-manager/InactivityManager';
import RsCookieConsent from './components/rs-cookie-consent/RsCookieConsent';
import RsOverlay from './components/rs-overlay/RsOverlay';
import ScrollToTopComponent from './components/scroll-to-top/ScrollToTopComponent';
import { SocketManager } from './components/socket-manager';
import { ToastNotification } from './constants/ToastNotification';
import reactTooltipClass from './react-tooltip-class';
import { AppActions } from './redux/redux-app/app-actions';
import { getAppInitialized, getSyncTimestamp } from './redux/redux-app/app-selectors';
import { getCurrentUserOrNull } from './redux/redux-auth/auth-selectors';
import { setCompanyExists } from './redux/redux-company/company-actions';
import { NotificationsActions } from './redux/redux-notifications/notifications-actions';
import RsApplicationRoutes from './routes/RsApplicationRoutes';
import { getAndPopulateCurrentUser } from './services/api/get-and-populate-current-user';
import { rsApiFetcher } from './services/api/RsApi';
import { initializeAmplify } from './services/aws/initialize-amplify';
import useScrollBlock from './services/utils/useScrollBlock';
import { AppState } from './store';
import './styles/main.scss';

const errorMiddleware: Middleware = (useSWRNext: SWRHook) => (key, fetcher, config) => {
  // ...
  const swr = useSWRNext(key, fetcher, config);
  useErrorHandler(swr.error);

  return swr;
};

export const RsAppContext = React.createContext<{
  chat: {
    unread: boolean;
    chatOn: boolean;
    setChatOn: (isOn: boolean) => void;
    setUnread: (isOn: boolean) => void;
  };
  chatbot: {
    chatbotOn: boolean;
    setChatbotOn: (isOn: boolean) => void;
  };
  scroll: {
    blockScroll: () => void;
    allowScroll: () => void;
  };
}>({
  chat: {
    unread: false,
    chatOn: false,
    setChatOn: () => {
      throw new Error('Not used correctly! Please correct this!');
    },
    setUnread: () => {
      throw new Error('Not used correctly! Please correct this!');
    },
  },
  chatbot: {
    chatbotOn: false,
    setChatbotOn: () => {
      throw new Error('Not used correctly! Please correct this!');
    },
  },
  scroll: {
    blockScroll: () => {
      throw new Error('Not used correctly! Please correct this!');
    },
    allowScroll: () => {
      throw new Error('Not used correctly! Please correct this!');
    },
  },
});

function RsApplicationComponent(props: ReduxStateProps & ReduxDispatchProps) {
  const syncTimestamp = useSelector(getSyncTimestamp);
  const appInitialized = useSelector(getAppInitialized);
  const currentUser = useSelector(getCurrentUserOrNull);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [chatOn, setChatOn] = useState<boolean>(false);
  const [isChatUnread, setIsChatUnread] = React.useState<boolean>(false);
  const [chatbotOn, setChatbotOn] = useState<boolean>(false);

  const initUser = async () => {
    try {
      await getAndPopulateCurrentUser(false);
    } catch (error) {}
  };

  useEffect(() => {
    initializeAmplify();
    initUser();

    /**
     * Listen for current logged in user ID in localstorage where amplify saves it automatically
     * and refresh the page when the user changes.
     *
     * The use case is when the user has multiple browser tabs opened at the same time and logs out
     * on one tab and / or logins into another account - all tabs should be refreshed
     *
     * @param e
     */
  }, []);

  useEffect(() => {
    if (syncTimestamp) {
      window.location.reload();
    }
  }, [syncTimestamp]);

  useEffect(() => {
    if (currentUser) {
      props.getAllNotifications();
    }
  }, [currentUser]);

  return (
    <RsAppContext.Provider
      value={{
        scroll: { blockScroll, allowScroll },
        chat: { chatOn, setChatOn, unread: isChatUnread, setUnread: setIsChatUnread },
        chatbot: { chatbotOn, setChatbotOn },
      }}
    >
      <SWRConfig
        value={{
          fetcher: rsApiFetcher,
          use: [errorMiddleware],
        }}
      >
        <BrowserRouter>
          <SocketManager />
          <RsCookieConsent />
          <RsOverlay />
          <InactivityManager />
          <ToastContainer limit={1} {...ToastNotification} />
          <ScrollToTopComponent />
          <RsApplicationRoutes appInitialized={appInitialized} />
          <div id={reactTooltipClass} />
        </BrowserRouter>
      </SWRConfig>
    </RsAppContext.Provider>
  );
}

type ReduxStateProps = {};

type ReduxDispatchProps = {
  setCompanyExists: Function;
  initializeApp: Function;
  getAllNotifications: Function;
};

const mapDispatchToProps: ReduxDispatchProps = {
  setCompanyExists: setCompanyExists,
  initializeApp: AppActions.setInitialized,
  getAllNotifications: NotificationsActions.getAllNotifications,
};

const mapStateToProps = (_state: AppState): ReduxStateProps => {
  return {};
};

export default Sentry.withProfiler(connect(mapStateToProps, mapDispatchToProps)(RsApplicationComponent));
