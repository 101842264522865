import { isNumber } from 'lodash';
import { numberFormatter } from './number.formatter';

type percentageOptionsType = {
  withPercentageSign?: boolean;
};

/**
 * Converts number or string into a string with 'fixedBy' digits
 *
 * @param input
 * @param fixedBy
 */
export const toFixed = (input: string | number | null, fixedBy: number = 2): string => {
  return input === undefined || input === null || input === '' ? '' : amountInNumber(input).toFixed(fixedBy);
};

export function formatAmount(amount: string | number): string {
  amount = isNumber(amount) ? `${amount}` : amount;
  if (!amount || amount === '') return '';
  return numberFormatter.amount.twoDecimals.format(+amount);
}

export const percentageFormat = (inputAmount: number | string | null, options?: percentageOptionsType): string => {
  let sign = options && options.withPercentageSign;

  if (!inputAmount && inputAmount !== 0) {
    return '';
  }

  return formatPercentage(numberFormatter.percentage.threeDecimals.format(amountInNumber(inputAmount)), sign);
};

/**
 * Converts an input (number or string) into a number
 *
 * @param {*} inputAmount
 */
export const amountInNumber = (inputAmount: number | string | boolean) => {
  let outputAmount: number = 0;

  if (typeof inputAmount === 'boolean') return inputAmount ? 1 : 0;
  if (typeof inputAmount === 'string') outputAmount = parseFloat(inputAmount || '0');
  else outputAmount = inputAmount;
  return isFinite(outputAmount) ? outputAmount : 0;
};

export function formatPercentage(value: string | number, withSign: boolean = true): string {
  if (value === '') return '';
  return withSign ? value + '%' : `${value}`;
}

export function formatFractionAsPercentage(value: number, options?: percentageOptionsType): string {
  return percentageFormat(value * 100, { ...options });
}

export const arrayOfNumbersToRecord = (array?: (number | undefined)[]): Record<number, number> | undefined => {
  if (!array) return;
  const result: Record<number, number> = {};
  array.forEach((element, index) => {
    if (element != undefined) result[index] = element;
  });
  return result;
};

export const recordOfNumbersToArray = (record?: Record<string, number>): (number | undefined)[] | undefined => {
  if (!record) return;
  const result: (number | undefined)[] = [];
  for (const [key, value] of Object.entries(record)) {
    result[parseInt(key)] = value;
  }
  return result;
};

export const cleanRecordOfNumbers = (array?: (number | undefined)[]): (number | undefined)[] | undefined => {
  if (!array) return;
  const result: (number | undefined)[] = [];
  array.forEach((element, index) => (result[index] = typeof element === 'number' ? element : undefined));
  return result;
};

export const percentageToFractionArray = (element: number | undefined): number | undefined => {
  return element != undefined ? element / 100 : undefined;
};

export const fractionToPercentageArray = (element: number | undefined): number | undefined => {
  return element != undefined ? element * 100 : undefined;
};
