import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { Option } from '../common/Option';
import { CompanyPublicMapType, CompanyPublicType } from '../company/CompanyPublicType';
import {
  informationUndertakingQualifications,
  InformationUndertakingQualificationType,
} from '../covenants/InformationUndertakingSpecType';
import {
  AllAmortisationPartialPrepayment,
  AllAmortisationTypes,
  AllFacilityTypes,
  AllHedgingInterestInstruments,
  AllLoanPropertyTypes,
  AllMarketValueEstBy,
  AllRelevantMarkets,
  AllTypesOfGuarantees,
  DocuSignIdentityVerificationType,
  TemplateType,
} from '../fa/NegotiationType';
import { AllBaseRates } from '../loan/BaseRateNameType';
import { AllCovenantTestingDateLabels, CovenantTestingDatesType } from '../loan/CovenantTestingDatesType';
import { AllInterestPeriodLabels } from '../loan/InterestPeriodType';
import { AllPrepaymentReasons } from '../loan/PrepaymentTypes';
import { matchString } from '../utils/strings';

export type GenericOption<T, K> = {
  value: T;
  label: K;
  disabled?: boolean;
};

type LabelMap = {
  // Map from option value to label.
  [key: string]: string;
};

export const optionsFromLiterals = <V extends string = string>(
  xs: readonly V[],
  lbl: (lbl: V) => string = x => x
): { value: V; label: string }[] => xs.map(x => ({ value: x, label: lbl(x) }));

function optionsToLabelMap(options: Option[]): LabelMap {
  const result: LabelMap = {};
  options.forEach(option => {
    result[option.value] = option.label;
  });
  return result;
}

export const getOptionValue = (o: Option[] | Option | '') =>
  Array.isArray(o) ? o[0].value : typeof o === 'string' ? o : o.value;

export const isOptionDisabled = (o: Option[] | Option | '') =>
  (!Array.isArray(o) && typeof o === 'object' && o.disabled) ?? false;

export const Currency: Option[] = require('./currencies.json');

export const CurrencyToLabel = optionsToLabelMap(Currency);

/*
  All countries or combinations of counties (such as GB-ENG or GB-ENG-WLS)

  Elements from this list are selected individually to use for applicable laws,
  holiday calendars or others.
*/
const countryOptions = [
  {
    label: 'Denmark',
    value: 'DK',
  },
  {
    label: 'England',
    value: 'GB-ENG',
  },
  {
    label: 'England & Wales',
    value: 'GB-ENG-WLS',
  },
  {
    label: 'Finland',
    value: 'FI',
  },
  {
    label: 'Germany',
    value: 'DE',
  },
  {
    label: 'Luxembourg',
    value: 'LU',
  },
  {
    label: 'Norway',
    value: 'NO',
  },
  { label: 'Poland', value: 'PL' },
  {
    label: 'Sweden',
    value: 'SE',
  },
  {
    label: 'Switzerland',
    value: 'CH',
  },
];

export const CountryToLabel = optionsToLabelMap(countryOptions);

function countriesToOptions(countries: string[]): Option[] {
  return countries.map(country => {
    return {
      value: country,
      label: CountryToLabel[country],
    };
  });
}

export const ApplicableLaw: Option[] = countriesToOptions(['DK', 'GB-ENG', 'FI', 'LU', 'NO', 'SE', 'PL']);
export const SLHolidayCalendar: Option[] = countriesToOptions(['DK', 'GB-ENG-WLS', 'FI', 'DE', 'LU', 'NO', 'SE', 'PL']);
export const LegalOpinionCountry: Option[] = countriesToOptions(['SE', 'NO', 'FI', 'DK']);

export const RelevantMarket: Option[] = [
  {
    value: 'stockholm',
    label: AllRelevantMarkets.stockholm,
  },
  {
    value: 'europe',
    label: AllRelevantMarkets.europe,
  },
];

export const MarketValueEstByOptions: Option[] = [
  {
    value: 'crbe',
    label: AllMarketValueEstBy.crbe,
  },
  {
    value: 'jll',
    label: AllMarketValueEstBy.jll,
  },
  {
    value: 'cushmanAndWakefield',
    label: AllMarketValueEstBy.cushmanAndWakefield,
  },
  {
    value: 'newsec',
    label: AllMarketValueEstBy.newsec,
  },
  {
    value: 'colliers',
    label: AllMarketValueEstBy.colliers,
  },
  {
    value: 'savills',
    label: AllMarketValueEstBy.savills,
  },
  {
    value: 'catella',
    label: AllMarketValueEstBy.catella,
  },
  {
    value: 'borrower',
    label: AllMarketValueEstBy.borrower,
  },
  {
    value: 'others',
    label: AllMarketValueEstBy.others,
  },
];

export const LoanProperties: Option[] = [
  {
    label: AllLoanPropertyTypes.office,
    value: 'office',
  },
  {
    label: AllLoanPropertyTypes.retail,
    value: 'retail',
  },
  {
    label: AllLoanPropertyTypes.residential,
    value: 'residential',
  },
  {
    label: AllLoanPropertyTypes.industrial,
    value: 'industrial',
  },
  {
    label: AllLoanPropertyTypes.logistics,
    value: 'logistics',
  },
  {
    label: AllLoanPropertyTypes.hotel,
    value: 'hotel',
  },
  {
    label: AllLoanPropertyTypes.care,
    value: 'care',
  },
  {
    label: AllLoanPropertyTypes.public_infrastructure,
    value: 'public_infrastructure',
  },
  {
    label: AllLoanPropertyTypes.other,
    value: 'other',
  },
];

export const BaseRate: Option[] = [
  {
    value: 'EURIBOR',
    label: AllBaseRates.EURIBOR,
  },
  {
    value: 'STIBOR',
    label: AllBaseRates.STIBOR,
  },
  {
    value: 'NIBOR',
    label: AllBaseRates.NIBOR,
  },
  {
    value: 'LIBOR',
    label: AllBaseRates.LIBOR,
  },
  {
    value: 'CIBOR',
    label: AllBaseRates.CIBOR,
  },
  {
    value: 'WIBOR',
    label: AllBaseRates.WIBOR,
  },
];

export const CurrencyBaseRate: { [key: string]: keyof typeof AllBaseRates } = {
  DKK: AllBaseRates.CIBOR,
  EUR: AllBaseRates.EURIBOR,
  SEK: AllBaseRates.STIBOR,
  NOK: AllBaseRates.NIBOR,
  CHF: AllBaseRates.LIBOR,
  PLN: AllBaseRates.WIBOR,
};

export const InterestRatePeriod = [
  {
    value: 'monthly',
    label: AllInterestPeriodLabels['monthly'],
  },
  {
    value: 'quarterly',
    label: AllInterestPeriodLabels['quarterly'],
  },
  {
    value: 'semi-yearly',
    label: AllInterestPeriodLabels['semi-yearly'],
  },
  {
    value: 'yearly',
    label: AllInterestPeriodLabels['yearly'],
  },
] as const;

export const MaturityInYears: Option[] = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];

export const MaturityInYearsNegotiation: Option[] = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: 'set-date',
    label: 'Set Date',
  },
];

export const TypesOfGuarantees: Option[] = [
  { value: 'onDemand', label: AllTypesOfGuarantees.onDemand },
  { value: 'corporateGuarantee', label: AllTypesOfGuarantees.corporateGuarantee },
  { value: 'cashCollateral', label: AllTypesOfGuarantees.cashCollateral },
  { value: 'letterOfSupport', label: AllTypesOfGuarantees.letterOfSupport },
  { value: 'letterOfIntent', label: AllTypesOfGuarantees.letterOfIntent },
];

export const amortisationTypeOptions: Option[] = [
  {
    value: 'bullet',
    label: AllAmortisationTypes.bullet,
  },
  {
    value: 'per-annum',
    label: AllAmortisationTypes['per-annum'],
  },
  {
    value: 'custom',
    label: AllAmortisationTypes.custom,
  },
];

export const FacilityTypesLabels = {};

export const FacilityTypes: Option[] = [
  {
    value: 'termLoan',
    label: AllFacilityTypes.termLoan,
  },
];

export const AmortisationFrequency: Option[] = [
  {
    value: 'monthly',
    label: AllInterestPeriodLabels['monthly'],
  },
  {
    value: 'quarterly',
    label: AllInterestPeriodLabels['quarterly'],
  },
  {
    value: 'semi-yearly',
    label: AllInterestPeriodLabels['semi-yearly'],
  },
  {
    value: 'yearly',
    label: AllInterestPeriodLabels['yearly'],
  },
];

export const AmortisationBase: Option[] = [
  {
    value: 'loan-size',
    label: 'Straight Line',
  },
  {
    value: 'outstanding-debt',
    label: 'Declining Balance',
  },
];

export const AmortisationPartialPrepayment: Option[] = [
  {
    value: 'inverseChronological',
    label: AllAmortisationPartialPrepayment.inverseChronological,
  },
  {
    value: 'chronological',
    label: AllAmortisationPartialPrepayment.chronological,
  },
];

export const HedgingInterestInstrument: Option[] = [
  {
    value: 'none',
    label: AllHedgingInterestInstruments.none,
  },
  {
    value: 'swap',
    label: AllHedgingInterestInstruments.swap,
  },
  {
    value: 'cap',
    label: AllHedgingInterestInstruments.cap,
  },
];

export function getCovenantTestingDatesOption(dates: CovenantTestingDatesType) {
  return {
    value: dates,
    label: AllCovenantTestingDateLabels[dates],
  };
}

export const CovenantTestingDate: Option[] = [
  {
    value: 'Q1',
    label: AllCovenantTestingDateLabels['Q1'],
  },
  {
    value: 'Q2',
    label: AllCovenantTestingDateLabels['Q2'],
  },
  {
    value: 'Q3',
    label: AllCovenantTestingDateLabels['Q3'],
  },
  {
    value: 'Q4',
    label: AllCovenantTestingDateLabels['Q4'],
  },
  {
    value: 'Q1-Q3',
    label: AllCovenantTestingDateLabels['Q1-Q3'],
  },
  {
    value: 'Q2-Q4',
    label: AllCovenantTestingDateLabels['Q2-Q4'],
  },
  {
    value: 'Q1-Q2-Q3-Q4',
    label: AllCovenantTestingDateLabels['Q1-Q2-Q3-Q4'],
  },
];

export const CovenantTestingDates = [
  {
    label: 'Every quarter Q1 Q2 Q3 Q4',
    value: 'Q1-Q2-Q3-Q4',
  },
  {
    label: 'Twice per year Q1 Q3',
    value: 'Q1-Q3',
  },
  {
    label: 'Twice per year Q2 Q4',
    value: 'Q2-Q4',
  },
  {
    label: 'Once per year Q1',
    value: 'Q1',
  },
  {
    label: 'Once per year Q2',
    value: 'Q2',
  },
  {
    label: 'Once per year Q3',
    value: 'Q3',
  },
  {
    label: 'Once per year Q4',
    value: 'Q4',
  },
];

export function getCompanyOptions(companies: CompanyPublicType[]): Option[] {
  return companies.map(company => ({
    value: company.id,
    label: company.companyInformation.companyName,
  }));
}

export function getCompanyOptionsFromMap(companiesMap: CompanyPublicMapType | undefined): Option[] {
  if (!companiesMap) {
    return [];
  }

  return Object.entries(companiesMap).map(([id, company]) => {
    return {
      value: id,
      label: company.companyInformation.companyName,
    };
  });
}

export function getTemplateOptions(templates: TemplateType[] | undefined): Option[] {
  if (!templates) return [];
  return templates.map(template => ({
    value: template.key,
    label: template.name,
  }));
}

export function getIdentityVerificationOptions(
  identityVerification: DocuSignIdentityVerificationType | undefined
): Option[] {
  if (!identityVerification) return [];
  return identityVerification.identityVerification.map(workflow => {
    return {
      value: workflow.workflowId,
      label: workflow.defaultName,
    };
  });
}

export function optionStripEmpty(options: readonly (Option | string)[]) {
  const variants = options.map(o => (typeof o === 'object' ? o.value : o));
  return Yup.mixed()
    .transform(x => (isEmpty(x) ? undefined : x))
    .oneOf(variants);
}

export function getValidation(options: readonly (Option | string)[], nullable: boolean = false) {
  const variants = options.map(o => (typeof o === 'object' ? o.value : o));
  if (nullable) {
    return Yup.mixed().oneOf([null, ...variants]);
  }

  return Yup.mixed().oneOf(variants);
}

export const NoneOption: Option = {
  value: '',
  label: '— None —',
};

export const UtilisationLoanStartOption: Option[] = [
  {
    value: 'at-utilisation',
    label: 'At utilisation',
  },
  {
    value: 'custom',
    label: 'Custom Start Date',
  },
];

export const ConstructionLoanStartOption: Option[] = [
  {
    value: 'at-first-drawdown',
    label: 'At First Drawdown',
  },
];

export const BasicInterestTypeOption: Option[] = [
  {
    value: 'fixed',
    label: 'Fixed',
  },
  {
    value: 'floating',
    label: 'Floating',
  },
];

export const InterestTypeOption: Option[] = [
  {
    value: 'fixed',
    label: 'Fixed',
  },
  {
    value: 'floating',
    label: 'Floating',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
];

export const DayCountConventions: Option[] = [
  {
    value: 'actual-360',
    label: 'Actual/360',
  },
  {
    value: 'actual-365',
    label: 'Actual/365',
  },
  {
    value: 'actual-actual',
    label: 'Actual/Actual',
  },
  {
    value: '30-360',
    label: '30/360',
  },
  {
    value: '30-365',
    label: '30/365',
  },
];

export const InterestPeriodAlignments: Option[] = [
  {
    value: 'calendar',
    label: 'Calendar',
  },
  {
    value: 'utilisation',
    label: 'Utilisation',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
];

export const ScheduleOption: Option[] = [
  {
    value: 'per-annum',
    label: 'Pct. per annum',
  },
  {
    value: 'custom',
    label: 'Custom schedule',
  },
];

export const BookingModelOption: Option[] = [
  {
    value: 'direct',
    label: 'Direct',
  },
  {
    value: 'paying-agent',
    label: 'Realstocks as paying agent with tax',
  },
];

export const TerminationOption: Option[] = [
  {
    value: 'utilisation-years',
    label: 'Utilisation + term',
  },
  {
    value: 'agreement-years',
    label: 'Agreement + term',
  },
  {
    value: 'custom',
    label: 'Custom Termination Date',
  },
];
export const ConstructionEndAvailabilityOption: Option[] = [
  {
    value: 'project-completion-date',
    label: 'Project Completion Date',
  },
];

export const CovenantStatusOption: Option[] = [
  {
    value: 'ok',
    label: 'OK',
  },
  {
    value: 'soft-breach',
    label: 'Soft Breach',
  },
  {
    value: 'hard-breach',
    label: 'Hard Breach',
  },
];

export const PrepaymentReasonOption: Option[] = [
  {
    value: 'covenantBreach',
    label: AllPrepaymentReasons.covenantBreach,
  },
  {
    value: 'voluntaryPrepayment',
    label: AllPrepaymentReasons.voluntaryPrepayment,
  },
  {
    value: 'termination',
    label: AllPrepaymentReasons.termination,
  },
  {
    value: 'other',
    label: AllPrepaymentReasons.other,
  },
];

export const prettyQualification = (q: InformationUndertakingQualificationType) =>
  matchString(q, {
    audited: () => 'Audited',
    unaudited: () => 'Unaudited',
  });

export const informationUndertakingQualificationOptions = optionsFromLiterals(
  informationUndertakingQualifications,
  prettyQualification
);

export const PlatformInteractionsReceiveData = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'emails',
    label: 'Emails',
  },
];

export const PlatformInteractionsReceiveDataExtended = [
  ...PlatformInteractionsReceiveData,
  {
    value: 'e-invoice',
    label: 'E-Invoice',
  },
];

export const DateRollingOptions: Option[] = [
  {
    value: 'modified-next-business-day',
    label: 'Modified following business day',
  },
  {
    value: 'prev-business-day',
    label: 'Preceding business day',
  },
];
