import { isValid } from 'date-fns';
import * as Yup from 'yup';
import { RsUploadedFileType } from '../common/RsUploadedFileType';
import { PartialSum } from '../utils/type-utils';
import {
  ApplicableLaw,
  BookingModelOption,
  Currency,
  getValidation,
  InterestTypeOption,
  LegalOpinionCountry,
  UtilisationLoanStartOption,
} from './options';

function isNonNegativeNumber(str: string) {
  const value = parseFloat(str);
  return !isNaN(value) && isFinite(+str) && value >= 0;
}

export const NonEmptyString = Yup.string().trim();
export const NonNegativeInteger = Yup.number().integer('Integer value expected').min(0, 'Non-negative value expected');

// Use this to disable validation for attributes that do field level validation.
export const OptionalString = Yup.string();
export const RequiredString = (msg: string) => Yup.string().required(msg).min(1, msg);

const PhoneValidationRegex = /^([0-9]{6,})*$/g;
export const PhoneValidation = Yup.string().matches(PhoneValidationRegex, 'Phone Number is invalid.');

export const IbanValidation = Yup.string().test({
  name: 'iban',
  message: 'Provided IBAN is invalid.',
  test: (value: string) => (!value ? true : value.length > 4),
});

export const NonNegativeDecimal = Yup.string()
  .trim()
  .test({
    name: 'NonNegativeDecimal',
    exclusive: false,
    message: 'Non-negative amount expected',
    test: (value: string) => value === undefined || value === '' || isNonNegativeNumber(value),
  });

export const PositiveNumber = Yup.number();

export const NonNegativeNumber = Yup.string()
  .trim()
  .test({
    name: 'NonNegativeNumber',
    exclusive: false,
    message: 'Non-negative number expected',
    test: (value: string) => value === undefined || value === '' || isNonNegativeNumber(value),
  });

export const PercentageValidation = Yup.number().positive().min(0).max(100);

export const RequiredApplicableLaw = getValidation(ApplicableLaw);

export const RequiredLegalOpinionCountry = getValidation(LegalOpinionCountry);

export const RequiredNonNegativeDecimal = NonNegativeDecimal.required('Amount is required');
export const OptionalNonNegativeDecimal = NonNegativeDecimal;
export const OptionalNonNegativeNumber = NonNegativeNumber;
export const RequiredNonNegativeNumber = NonNegativeNumber.required('Number is required');
export const RequiredNonNegativeNumberWithMsg = (msg: string) => NonNegativeNumber.required(msg);

export const RequiredCurrency = getValidation(Currency).required('Currency is required');
export const OptionalRequiredCurrency = getValidation(Currency);
export const OptionalDateDay = (invalidDateMsg: string) => Yup.string().typeError(invalidDateMsg).nullable();
export const OptionalDays = NonNegativeInteger;
export const RequiredDays = NonNegativeInteger.required();

export const LegalOpinion = Yup.object({
  legal_opinion_law_firm: NonEmptyString,
  legal_opinion_country: RequiredLegalOpinionCountry,
});

export const RequiredLoanStart = getValidation(UtilisationLoanStartOption).required('Loan start is required');
export const RequiredInterestType = getValidation(InterestTypeOption).required('Interest Type is required');
export const RequiredBookingModelType = getValidation(BookingModelOption).required('Booking Model is required');

const STATUS = ['uploading', 'error', 'success', 'ready'] as const;
type Status = (typeof STATUS)[number];

export const RsFileValidation = Yup.object<RsUploadedFileType>({
  id: Yup.string().required(),
  name: Yup.string().required(),
  fieldName: Yup.string().required(),
  bucket: Yup.string().required(),
  url: Yup.string(),
  key: Yup.string(),
  status: Yup.mixed<Status>().oneOf(STATUS.slice()).required(),
  metadata: Yup.object({
    acl: Yup.string().required(),
    isCover: Yup.boolean(),
  }) as Yup.Schema<{
    acl: string;
    isCover: boolean;
  }>,
  lastModified: Yup.number().min(0),
  createdAt: Yup.string(),
  data: Yup.string().nullable(),
  type: Yup.string().required(),
});

export const RsDateFieldValidator = () => {
  return Yup.mixed<string | null | undefined>().test('RsDateField', 'Invalid Date', (value: any) => {
    if (typeof value === 'string' && value.trim().length > 0) {
      return isValid(new Date(value));
    }
    if (value === null || value === undefined) {
      return true;
    }

    return false;
  });
};

/**
 * Validator for string literals
 * @param lit
 * @returns
 */
export function literal<T extends string>(lit: T | readonly T[]): Yup.MixedSchema<T> {
  return Yup.mixed()
    .transform(x => (x.trim() === '' ? undefined : x))
    .oneOf(Array.isArray(lit) ? lit : [lit]);
}

type DistributeSchema<T extends Yup.Schema<any>[]> = T[number] extends Yup.Schema<infer P> ? P : never;
type PartialSumIfNotRequired<Condition extends boolean, T> = Condition extends false ? PartialSum<T> : T;

/**
 * Validator for unions
 * @param cases The Yup validators for the individual cases of the union
 * @returns A Yup validator for the union of the cases
 */
export function union<T extends Yup.Schema<any>[]>(...cases: T): Yup.MixedSchema<DistributeSchema<T>> {
  return Yup.mixed<any>().test(
    'union',
    () => ({
      message: 'Value must be one of the cases',
      cases: cases.map(it => it.describe()),
    }),
    data => cases.some(schema => schema.isValidSync(data))
  );
}

/**
 * Validator for sum-types i.e. discriminated unions
 * @param cases The Yup validators for the individual cases of the sum type
 * @returns A Yup validator for the discriminated union of the cases
 */
export function sumType<K extends string, T extends Yup.ObjectSchema<any>, Required extends boolean = false>(
  schema: Record<K, T>,
  required?: Required
): Yup.Schema<PartialSumIfNotRequired<Required, DistributeSchema<(typeof schema)[K][]>>> {
  return Yup.lazy((v: { type: K } | undefined) => {
    if (!v || !v?.type) {
      if (!required) return Yup.mixed().notRequired();
      else return Yup.object({ type: Yup.string().required('Missing required key') });
    }
    if (!(v.type in schema)) {
      return Yup.object({ type: Yup.string().test('unknown-type', `Unknown type ${v.type}`, () => false) });
    }
    return schema[v.type].concat(Yup.object({ type: literal(v.type) }));
  });
}

/**
 * Validator for a sum type value with a required 'type' tag.
 *
 * Example of validation of sum type with 4 variants:
 *
 * export const SLInterestRateValidation = sumTypeRequired({
 *   fixed: FixedInterestRateValidation,
 *   floating: FloatingInterestRateValidation,
 *   custom: CustomInterestRateValidation,
 *   'custom-dynamic': CustomDynamicInterestRateValidation,
 * });
 */
export function sumTypeRequired<K extends string, T extends Yup.ObjectSchema<any>>(
  schema: Record<K, T>
): Yup.Schema<DistributeSchema<(typeof schema)[K][]>> {
  return sumType(schema, true);
}

/*
 * Validator for sum types that allows empty values and converts objects that
 * don't have a 'type' tag to undefined.
 */
export function sumTypeStripEmpty<K extends string, T extends Yup.ObjectSchema<any>>(
  schema: Record<K, T>
): Yup.Schema<PartialSumIfNotRequired<false, DistributeSchema<(typeof schema)[K][]>>> {
  return Yup.lazy((v?: { type: K }) => {
    if (!v || !v.type) return Yup.mixed().transform(_ => undefined) as unknown as any;
    if (!(v.type in schema)) {
      return Yup.object({ type: Yup.string().test('unknown-type', `Unknown type ${v.type}`, () => false) });
    }
    return schema[v.type];
  }) as unknown as any;
}
