import { Dispatch } from 'redux';
import { RsApiEndpoints } from '../../constants/RsApiEndpoints';
import { RsApi } from '../../services/api/RsApi';
import { ChatConversation, ChatConversations, ChatMessage, ChatWindow } from '../../types/chat';
import { ReduxAction } from '../redux';

export const CHAT_ACTIONS = {
  getChatContacts: 'GET_CHAT_CONTACTS',
  addChatWindow: 'ADD_CHAT_WINDOW',
  closeChatWindow: 'CLOSE_CHAT_WINDOW',
  setChatMessages: 'SET_CHAT_MESSAGES',
  pushNewMessage: 'PUSH_NEW_MESSAGE',
  //! NEW CHAT HERE
  getCompanyContacts: 'GET_COMPANY_CONTACTS',
  getUsersInCompany: 'GET_USERS_IN_COMPANY',
  getConversations: 'GET_CONVERSATIONS',
  searchContacts: 'SEARCH_CONTACTS',
  setConversationsLoading: 'SET_CONVERSATIONS_LOADING',
  setContactsLoading: 'SET_CONTACTS_LOADING',
  setConversationsError: 'SET_CONVERSATIONS_ERROR',
  setContactsError: 'SET_CONTACTS_ERROR',
  getMessages: 'GET_MESSAGES',
  setConversations: 'SET_CONVERSATIONS',
  addConversation: 'ADD_CONVERSATION',
  setUserPresence: 'SET_USER_PRESENCE',
  setConversationAsRead: 'SET_CONVERSATION_AS_READ',
  resetChatState: 'RESET_CHAT_STATE',
};

export const ChatActions = {
  resetChatState: () => {
    return { type: CHAT_ACTIONS.resetChatState };
  },
  addChatWindow: (chatWindow: ChatWindow) => {
    return { type: CHAT_ACTIONS.addChatWindow, payload: chatWindow };
  },
  closeChatWindow: (windowIndex: number) => {
    return { type: CHAT_ACTIONS.closeChatWindow, payload: windowIndex };
  },
  setChatMessages: (messages: Array<ChatMessage>, conversationID: string) => {
    return {
      type: CHAT_ACTIONS.setChatMessages,
      payload: { messages: messages, conversationID: conversationID },
    };
  },
  pushNewMessage: (message: any) => {
    return {
      type: CHAT_ACTIONS.pushNewMessage,
      payload: { message: message },
    };
  },
  //! NEW CHAT HERE
  getConversations: (message: any) => {
    return {
      type: CHAT_ACTIONS.getConversations,
      payload: message,
    };
  },
  setConversationAsRead: (conversationID: string) => {
    return {
      type: CHAT_ACTIONS.setConversationAsRead,
      payload: conversationID,
    };
  },
  setConversations: (message: any) => {
    return {
      type: CHAT_ACTIONS.setConversations,
      payload: message,
    };
  },
  addConversation: (message: any) => {
    return {
      type: CHAT_ACTIONS.addConversation,
      payload: message,
    };
  },
  searchContacts: (message: any) => {
    return {
      type: CHAT_ACTIONS.searchContacts,
      payload: message.filteredContacts,
    };
  },
  setContactsLoading: (message: any) => {
    return {
      type: CHAT_ACTIONS.setContactsLoading,
      payload: message,
    };
  },
  getMessages: (messages: any, conversationID: string) => {
    return {
      type: CHAT_ACTIONS.getMessages,
      payload: {
        messages,
        conversationID,
      },
    };
  },
  setUserPresence: (contactID: string, status: string) => {
    return {
      type: CHAT_ACTIONS.setUserPresence,
      payload: {
        contactID,
        status,
      },
    };
  },
};

// TODO: cleanup
export function searchContacts(queryString: string) {
  try {
    const contacts = RsApi.get({
      path: RsApiEndpoints.chat.searchContacts,
      config: {
        queryStringParameters: {
          q: queryString,
        },
      },
    });
    return (dispatch: Dispatch<ReduxAction>) => {
      dispatch(ChatActions.setContactsLoading(true));
      contacts.then((res) => {
        dispatch(ChatActions.setContactsLoading(false));
        dispatch(ChatActions.searchContacts(res.message));
        return res.message;
      });
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

const getConversationsFromApi = async () => {
  return RsApi.get({ path: RsApiEndpoints.chat.getConversations });
};

export function getConversations() {
  return (dispatch: Dispatch<ReduxAction>) => {
    getConversationsFromApi().then(
      (res) => {
        const conversations: ChatConversations = {};
        for (let conversation of res.message.conversations) {
          conversations[conversation.conversationID] = {
            ...conversations[conversation.conversationID],
            ...conversation,
          };
        }
        dispatch(ChatActions.getConversations(conversations));
        return res.message;
      },
      // eslint-disable-next-line no-console
      (err) => console.log(err)
    );
  };
}

export function setConversationAsRead(conversationID: string) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(ChatActions.setConversationAsRead(conversationID));
  };
}

export function newConversation(conversation: ChatConversation) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(ChatActions.addConversation(conversation));
    dispatch(
      ChatActions.addChatWindow({
        conversationID: conversation.conversationID,
        conversation: conversation,
        chatMessages: null,
      })
    );
    return conversation;
  };
}

export function setChatWindow(conversation: ChatConversation) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(
      ChatActions.addChatWindow({
        conversationID: conversation.conversationID,
        conversation: conversation,
        chatMessages: null,
      })
    );
  };
}

export function closeChatWindow(windowIndex: number) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(ChatActions.closeChatWindow(windowIndex));
  };
}

export function getMessages(conversationID: string, resolve?: Function) {
  const messages = RsApi.get({ path: `${RsApiEndpoints.chat.getMessages}/${conversationID}` });
  return (dispatch: Dispatch<ReduxAction>) => {
    messages.then((res) => {
      dispatch(ChatActions.getMessages(res.message.messages, conversationID));
      dispatch(ChatActions.setConversationAsRead(conversationID));
      if (resolve) {
        return resolve(res.message);
      } else {
        return res.message;
      }
    });
  };
}

export function setChatMessages(messages: Array<ChatMessage>, conversationID: string) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(ChatActions.setChatMessages(messages, conversationID));
  };
}

export function pushNewMessage(message: ChatMessage) {
  return (dispatch: Dispatch<ReduxAction>) => {
    getConversationsFromApi().then((res) => {
      const conversations: ChatConversations = {};
      for (let conversation of res.message.conversations) {
        conversations[conversation.conversationID] = {
          ...conversations[conversation.conversationID],
          ...conversation,
        };
      }
      dispatch(ChatActions.getConversations(conversations));
      dispatch(ChatActions.pushNewMessage(message));
      return res.message;
    });
  };
}

export function resetChatState() {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(ChatActions.resetChatState());
    getConversationsFromApi().then((res) => {
      const conversations: ChatConversations = {};
      for (let conversation of res.message.conversations) {
        conversations[conversation.conversationID] = {
          ...conversations[conversation.conversationID],
          ...conversation,
        };
      }
      dispatch(ChatActions.getConversations(conversations));
      return res.message;
    });
  };
}

export function setUserPresence(messageData: any) {
  const { contactID, status } = messageData;
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(ChatActions.setUserPresence(contactID, status));
  };
}
