import { OutboundNotificationDTO } from '@realstocks/types';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { ReduxAction } from '../redux';

// actions
export const OutboundNotificationsActions = {
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  CHECK_TOGGLE_ALL: 'CHECK_TOGGLE_ALL',
  CHECK_TOGGLE: 'CHECK_TOGGLE',
};

export const OutboundNotificationsActionCreators = {
  setNotifications: (notifications: readonly OutboundNotificationDTO[]) => {
    return { type: OutboundNotificationsActions.SET_NOTIFICATIONS, payload: notifications };
  },
  checkToggleNotification: (id: string, checked: boolean) => {
    return { type: OutboundNotificationsActions.CHECK_TOGGLE, payload: { id, checked } };
  },
  checkAllToggleNotification: (checked: boolean) => {
    return { type: OutboundNotificationsActions.CHECK_TOGGLE_ALL, payload: checked };
  },
};

//effects
export const setNotifications =
  (notifications: readonly OutboundNotificationDTO[]) => (dispatch: Dispatch<ReduxAction>) => {
    dispatch(OutboundNotificationsActionCreators.setNotifications(notifications));
  };

// selectors
const selectOutboundNotifications = (state: Record<string, any>) => {
  return state.outboundNotifications;
};
export const selectAllNotifications = createSelector(
  selectOutboundNotifications,
  (state: OutboundNotificationsState): StoreOutboundNotificationDTO[] => {
    return state.notifications;
  }
);
export const selectAllCheckedNotifications = createSelector(
  selectAllNotifications,
  (notifications: StoreOutboundNotificationDTO[]): StoreOutboundNotificationDTO[] => {
    return notifications.filter((n) => n.checked);
  }
);

// reducer
export type StoreOutboundNotificationDTO = OutboundNotificationDTO & { checked: boolean };
export type OutboundNotificationsState = {
  notifications: (OutboundNotificationDTO & { checked: boolean })[];
};

const initialState: OutboundNotificationsState = { notifications: [] };

export const OutboundNotificationReducer = (state: OutboundNotificationsState = initialState, action: ReduxAction) => {
  switch (action.type) {
    case OutboundNotificationsActions.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.map((n: StoreOutboundNotificationDTO) => ({ ...n, checked: false })),
      };
    case OutboundNotificationsActions.CHECK_TOGGLE:
      return {
        ...state,
        notifications: state.notifications.map((n) =>
          action.payload.id === n.id ? { ...n, checked: action.payload.checked } : n
        ),
      };
    case OutboundNotificationsActions.CHECK_TOGGLE_ALL:
      return {
        ...state,
        notifications: state.notifications.map((n) => ({ ...n, checked: action.payload })),
      };
    default:
      return state;
  }
};
