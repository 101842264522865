import clsx from 'clsx';
import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { nMemo } from 'utils/nMemo';
import './RsLabel.scss';

export type RsLabelProps = {
  id?: string;
  label: string | ReactNode;
  name: string;
  isListing?: boolean;
  children?: ReactNode;
  required?: boolean;
  classes?: string[];
};

export function RsLabel(props: RsLabelProps) {
  const { id, name, isListing, classes } = props;

  return (
    <label htmlFor={id || name} className={clsx(`rs-label`, isListing && 'listing', classes)}>
      {typeof props.label === 'string' ? <p dangerouslySetInnerHTML={{ __html: props.label }}></p> : props.label}
      {props.required && props.label && <span className="required-asterisk">*</span>}
    </label>
  );
}
export default RsLabel;

type SimpleProps = Omit<ComponentPropsWithoutRef<'label'>, 'htmlFor'> & { htmlFor: string; required?: boolean };
/**
 * RsLabel needs to be in a rs-input to function, which comes with its own baggage.
 * RsLabelSimple looks the same no matter where you use it.
 */
export const RsLabelSimple = nMemo('RsLabelSimple', ({ required, children, className, ...labelProps }: SimpleProps) => (
  <label
    {...labelProps}
    className={clsx('w-2/5 flex items-center justify-end pr-[18px] hyphens-none text-rs-grey-darker', className)}
  >
    {children}
    {required && <span className="text-rs-red font-bold relative left-1">*</span>}
  </label>
));
