// I'm worried, because this type is also used for recurrence of reports and other things
// Seems like we're using the same type for things that could develop in different directions.
export type InterestPeriodType = 'monthly' | 'quarterly' | 'semi-yearly' | 'yearly';

export const AllInterestPeriodLabels: Record<InterestPeriodType, string> = {
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  'semi-yearly': 'Half-Yearly',
  yearly: 'Yearly',
} as const;

export const interestPeriodTypes = Object.keys(AllInterestPeriodLabels) as InterestPeriodType[];

export type GetAvailableInterestPeriodsType = {
  loanId: string;
};
