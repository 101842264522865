import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ClientPath from '../../routes/ClientPath';
import './RegisterNowComponent.scss';

export interface IRegisterNowComponentProps {
  content?: React.ReactNode;
}

function RegisterNowComponent(props: IRegisterNowComponentProps) {
  const { content = <Fragment>Request a demo or get in contact to discuss your business needs.</Fragment> } = props;
  return (
    <div className="register-now-component">
      <div className="container">
        <div className="columns is-multiline is-centered is-vcentered">
          <div className="column is-full">
            <p>{content}</p>
          </div>
          <div className="column is-full">
            <div className="register-now-container">
              <Link to={ClientPath.static.contact}>
                <button type="button" className="button is-primary is-inverted">
                  GET IN TOUCH
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterNowComponent;
