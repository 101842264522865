import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import RsLoading from '../../components/rs-loading/RsLoading';
import { Breakpoints } from '../../constants/Generic';
import { NavigationContext } from '../../routes/NavigationContext';
import { RsNavigationLink } from '../rs-main-navigation/classes/RsNavigationLink';
import RsNavLink from '../rs-main-navigation/components/RsNavLink';
import './RsSecondaryNavigation.scss';

export type RsSecondaryNavigationProps = {
  links: RsNavigationLink[];
  leftLinkItem?: RsNavigationLink;
  rightLinkItem?: RsNavigationLink;
  sticky?: boolean;
  isModule?: boolean;
  hideOnDisallow?: boolean;
  classes?: string;
};

function RsSecondaryNavigation(props: RsSecondaryNavigationProps) {
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [refInitialPosition, setRefInitialPosition] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number | null>(null);
  const [isTouchDevice, setIsTouchDevice] = useState<boolean>(false);
  const [navExpanded, setNavExpanded] = useState<boolean>(false);
  const { loading: navigationLoading } = useContext(NavigationContext);
  const location = useLocation();
  const rsNavRef = useRef<HTMLDivElement>(null);
  const observer = useRef<any>(null);

  const handleMainNavDropShadow = (entries: any) => {
    const [entry] = entries;

    const search = document.getElementsByClassName('rs-main-navigation');

    if (search.length === 1) {
      const mainNav = search[0];

      mainNav.classList.toggle('drop-shadowed', !(entry.intersectionRatio < 1));
    }
  };

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }

    if (props.sticky && props.isModule) {
      observer.current = new IntersectionObserver(handleMainNavDropShadow, { threshold: [1] });
      if (rsNavRef.current) {
        observer.current.observe(rsNavRef.current);

        return () => {
          observer.current.disconnect();
        };
      }
    }
  }, [rsNavRef]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (rsNavRef && rsNavRef.current) {
      const rsNavigationCoordinates = getCoordinates(rsNavRef.current);
      const rsNavbarPosition = refInitialPosition - 88;
      if (refInitialPosition) {
        if (scrollPosition >= rsNavbarPosition && !isFixed) {
          setIsFixed(true);
        } else if (scrollPosition < rsNavbarPosition && isFixed) {
          setIsFixed(false);
        }
      } else {
        setRefInitialPosition(rsNavigationCoordinates.top);
      }
    }
  }, [scrollPosition, refInitialPosition]);

  useEffect(() => {
    if (windowWidth !== null) {
      if (windowWidth < Breakpoints.tablet) {
        setIsTouchDevice(true);
      } else {
        setIsTouchDevice(false);
      }
    }
  }, [windowWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    handleResizeWindow();

    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    setNavExpanded(false);
  }, [location.pathname]);

  const handleScroll = () => {
    if (rsNavRef && rsNavRef.current) {
      setScrollPosition(document.body.scrollTop);
    }
  };

  const handleResizeWindow = () => {
    setWindowWidth(window.innerWidth);

    if (rsNavRef.current) {
      const rsNavigationCoordinates = getCoordinates(rsNavRef.current);

      setRefInitialPosition(rsNavigationCoordinates.top);
    }
  };

  function getCoordinates(elem: HTMLElement) {
    // crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  }

  const { leftLinkItem, rightLinkItem, hideOnDisallow } = props;

  const stickyClassName = props.isModule ? 'sticky-to-main-nav-module' : 'sticky-to-main-nav';

  return (
    <div
      ref={rsNavRef}
      className={`rs-secondary-navigation ${props.classes || ''} ${props.sticky ? stickyClassName : ''}`}
    >
      <nav className="navbar" role="navigation" aria-label="secondary navigation">
        <div className="navbar-start">
          {navigationLoading ? (
            <RsLoading width="400px" center={true} height="20px" />
          ) : (
            <div
              className={`navbar-menu rs-secondary-nav-menu container ${
                isTouchDevice ? (navExpanded ? 'rs-secondary-nav-expanded' : 'rs-secondary-nav-collapsed') : ''
              }`}
              style={{
                maxHeight: navExpanded ? '500px' : '50px',
                overflow: 'hidden',
              }}
            >
              {isTouchDevice && (
                <RsNavLink
                  className="sections-item"
                  link={new RsNavigationLink(navExpanded ? 'Sections' : 'Sections', '', true).setCallback(() => {
                    setNavExpanded(!navExpanded);
                  })}
                ></RsNavLink>
              )}
              {leftLinkItem && (
                <RsNavLink
                  className="left-link-item"
                  key={`secondary_nav_link_${leftLinkItem.path}_${leftLinkItem.label}`}
                  link={leftLinkItem}
                  exact={true}
                ></RsNavLink>
              )}

              {props.links.map((link: RsNavigationLink) => (
                <RsNavLink
                  hideOnDisallow={hideOnDisallow}
                  key={`secondary_nav_link_${link.path}_${link.label}`}
                  link={link}
                  exact={true}
                ></RsNavLink>
              ))}

              {rightLinkItem && (
                <RsNavLink
                  className="right-link-item"
                  key={`secondary_nav_link_${rightLinkItem.path}_${rightLinkItem.label}`}
                  link={rightLinkItem}
                  exact={true}
                ></RsNavLink>
              )}
            </div>
          )}
        </div>
      </nav>
    </div>
  );
}

export default RsSecondaryNavigation;
