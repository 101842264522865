import { isString } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import RsList from '../../components/rs-elements/rs-list/RsList';
import { getStaticMedia } from '../../constants/Aws';
import './ExpandableListComponent.scss';

type ExpandableListComponentType = 'top-panel' | 'bottom-panel' | 'list-only';
/**
 * If it's 'top-panel' type it means that whenever the user clicks the bottom label it expands
 * the top list
 */
export const ExpandableListTopPanelType = 'top-panel' as ExpandableListComponentType;
/**
 * If it's the 'bottom-panel' type it means that the top panel remains intact
 * and upon expanding the bottom label adds the corresponding 'bottomPanelContent' prop content
 */
export const ExpandableListBottomPanelType = 'bottom-panel' as ExpandableListComponentType;

export const ListOnlyType = 'list-only' as ExpandableListComponentType;

export interface IExpandableListComponentProps {
  topPanelList: any[];
  type: ExpandableListComponentType; // the type of the component - see explanation on constants above.
  className?: string;
  footerNote?: string;
  title?: string;
  expandText?: string; // the bottom clickable label that expands the content. Default is "View All"
  collapseText?: string; // the bottom clickable label that collapses the content. Default is "View Less"
  bottomPanelList?: any[];
}

export default function ExpandableListComponent(props: IExpandableListComponentProps) {
  const collapsedItemsNumber = 5;

  const [maxHeight, setMaxHeight] = React.useState<number>(0);
  const [topPanelListToShow, setTopPanelListToShow] = React.useState<any[]>(
    props.type === ExpandableListTopPanelType ? props.topPanelList.slice(0, collapsedItemsNumber) : props.topPanelList
  );

  const [bottomPanelListShow, setBottomPanelListShow] = React.useState<any[]>([]);

  const topPanelListRefEl = React.useRef<HTMLUListElement>(null);
  const bottomPanelListRefEl = React.useRef<HTMLUListElement>(null);

  const toggleExpanded = () => {
    if (props.type === ExpandableListTopPanelType) {
      setTopPanelListToShow(
        topPanelListToShow.length === props.topPanelList.length
          ? props.topPanelList.slice(0, collapsedItemsNumber + 1)
          : props.topPanelList
      );
    } else {
      setBottomPanelListShow(props.bottomPanelList && bottomPanelListShow.length === 0 ? props.bottomPanelList : []);
    }
  };

  useEffect(() => {
    if (props.type === ExpandableListTopPanelType) {
      setTimeout(() => {
        setMaxHeight(topPanelListRefEl.current ? topPanelListRefEl.current.clientHeight : 0);
      }, 200);
    } else {
      setTimeout(() => {
        setMaxHeight(bottomPanelListRefEl.current ? bottomPanelListRefEl.current.clientHeight : 0);
      }, 200);
    }
  }, [topPanelListToShow, bottomPanelListShow]);

  return (
    <div className="expandable-list-container">
      <div
        className={`expandable-list-component ${props.type} ${
          ((props.type === ExpandableListTopPanelType &&
            props.topPanelList &&
            topPanelListToShow.length === props.topPanelList.length) ||
            (props.bottomPanelList && bottomPanelListShow.length === props.bottomPanelList.length)) &&
          'expanded'
        } ${props.className || ''}`}
      >
        <div className="expandable-list-component-header">{props.title && <h3>{props.title}</h3>}</div>

        <div
          className={`expandable-list-component-content top-panel ${props.type === ListOnlyType ? 'list-only' : ''}`}
          style={props.type === ExpandableListTopPanelType ? { maxHeight: `${maxHeight ? maxHeight : 0}px` } : {}}
        >
          <RsList checkmarked={true} items={topPanelListToShow} />
        </div>

        {props.type !== ListOnlyType && (
          <div className={`expandable-list-component-footer`} onClick={toggleExpanded}>
            {props.footerNote && <span className="footer-note">{props.footerNote}</span>}

            <div className="columns is-multiline">
              <div className="column is-full">
                <div className="expandable-bar">
                  {props.type === ExpandableListTopPanelType &&
                    props.topPanelList &&
                    props.topPanelList.length > collapsedItemsNumber && (
                      <h3>
                        {topPanelListToShow.length === props.topPanelList.length && (props.collapseText || 'View Less')}
                        {topPanelListToShow.length < props.topPanelList.length && (props.expandText || 'View All')}
                      </h3>
                    )}

                  {props.type === ExpandableListBottomPanelType &&
                    props.bottomPanelList &&
                    props.bottomPanelList.length && (
                      <h3>
                        <Fragment>
                          {bottomPanelListShow.length > 0 && props.collapseText}
                          {bottomPanelListShow.length === 0 && props.expandText}
                        </Fragment>
                      </h3>
                    )}

                  <img className="expand-icon" src={getStaticMedia('/icons/expand-icon.png')} />
                </div>
              </div>

              {props.type === ExpandableListBottomPanelType && bottomPanelListShow.length > 0 && (
                <div className="column is-full">
                  <div
                    className={`expandable-list-component-content bottom-panel`}
                    style={
                      props.type === ExpandableListBottomPanelType
                        ? { maxHeight: `${maxHeight ? maxHeight : 0}px` }
                        : {}
                    }
                  >
                    <ul ref={bottomPanelListRefEl}>
                      {bottomPanelListShow.map((listEl: string | React.ReactNode, idx: number) => {
                        return (
                          <li key={`expandable-list-element_${listEl}_${idx}`} className="expandable-list-el-row">
                            <img src={getStaticMedia('/icons/bullet-point.svg')} />
                            {isString(listEl) && <p>{listEl}</p>}
                            {!isString(listEl) && listEl}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
